@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {
    --black: #000000;
    --white: #ffffff;
    --yellow: #ffff00;
}

*,
::before,
::after {
    background-repeat: no-repeat;
    box-sizing: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
    outline: none !important;
}

#root {
    height: 100%;
}

html {
    box-sizing: border-box;
    cursor: default;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    font-family: 'PoppinsRegular';
}

body {
    margin: 0;
    height: 100%;
    overflow-y: overlay;
    font-family: 'PoppinsRegular';
}

ul{
    padding: 0;
    margin: 0;
}

ol,
ul li {
    font-family: 'PoppinsRegular';
    list-style-type: none;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
menu {
    display: block;
}

figure {
    margin: 0;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

nav ol,
nav ul {
    list-style: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-family: 'PoppinsSemiBold';
}

pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

dfn {
    font-style: italic;
}

mark {
    background-color: var(--yellow);
    color: var(--black);
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: initial;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

img {
    border-style: none;
    -webkit-user-drag: none;
}

svg {
    fill: currentColor;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
}

button,
input,
select,
textarea {
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

textarea {
    overflow: auto;
    resize: vertical;
}

[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type='search'] {
    appearance: textfield;
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

/**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

summary {
    display: list-item;
}

canvas {
    display: inline-block;
}

template {
    display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

[hidden] {
    display: none;
}

/* ARIA */

[aria-busy='true'] {
    cursor: progress;
}

/*
   * Change the cursor on control elements (opinionated).
   */
[aria-controls] {
    cursor: pointer;
}

/*
   * Change the display on visually hidden accessible elements (opinionated).
   */
[aria-hidden='false'][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute;
}

/*
   * Change the cursor on disabled, not-editable, or otherwise
   * inoperable elements (opinionated).
   */
[aria-disabled] {
    cursor: not-allowed;
}