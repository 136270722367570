@import './variables';

@mixin TopbarDesktop_label {
    display: inline-block;
    margin: 28px 0;
    text-decoration: inherit;

    @media screen and (min-width: $breakpoint_768) {
        margin: 28px 0;
    }
}

@mixin TopbarDesktop_linkHover {
    border-bottom: 0px solid;
    transition: $transitionStyleButton;

    &:hover,
    &:focus {
        border-bottom: 4px solid var(--avatusColor);
        border-radius: 0;
        text-decoration: none;
    }
}

@mixin avatusModalTitleStyles {
    font-family: $fontFamilySemiBold;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;

    @media screen and (min-width: $breakpoint_768) {
        font-family: $fontFamilySemiBold;
        line-height: 40px;
        margin: 0;
    }
}

@mixin avatusH1FontStyles {
    font-family: $fontFamilySemiBold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    position: relative;
    margin-top: 18px;
    margin-bottom: 18px;

    @media screen and (min-width: $breakpoint_768) {
        font-size: 40px;
        font-family: $fontFamilySemiBold;
        line-height: 48px;
        letter-spacing: -1px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    @media screen and (max-width: $breakpoint_576) {
        font-size: 20px;
    }
}

@mixin avatusH2FontStyles {
    font-family: $fontFamilySemiBold;
    font-size: 21px;
    line-height: 24px;
    margin-top: 21px;
    margin-bottom: 17px;

    @media screen and (min-width: $breakpoint_768) {
        line-height: 32px;
        margin-top: 21px;
        margin-bottom: 19px;
    }
}

@mixin avatusH3FontStyles {
    font-family: $fontFamilySemiBold;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 14px;

    @media screen and (min-width: $breakpoint_768) {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    @media screen and (max-width: $breakpoint_767) {
        font-size: 16px;
    }
}

@mixin avatusH4FontStyles {
    font-family: $fontFamilyMedium;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    margin-top: 12px;
    margin-bottom: 12px;

    @media screen and (min-width: $breakpoint_768) {
        font-family: $fontFamilyMedium;
        margin-top: 17px;
        margin-bottom: 15px;
    }
}

@mixin avatusH5FontStyles {
    font-family: $fontFamilyMedium;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    margin-top: 10px;
    margin-bottom: 8px;

    @media screen and (min-width: $breakpoint_768) {
        line-height: 16px;
        margin-top: 10px;
        margin-bottom: 14px;
    }
}

@mixin avatusH6FontStyles {
    font-family: $fontFamilyBold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 8px;

    @media screen and (min-width: $breakpoint_768) {
        line-height: 16px;
        margin-top: 10px;
        margin-bottom: 6px;
    }
}

@mixin avatusTinyFontStyles {
    font-family: $fontFamily;
    font-size: 13px;
    line-height: 18px;
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media screen and (min-width: $breakpoint_768) {
        line-height: 16px;
        margin-top: 10.5px;
        margin-bottom: 13.5px;
    }
}

@mixin avatusLinkStyles {
    display: inline;
    margin: 0;
    padding: 0;
    border: none;
    color: var(--avatusColor);
    text-decoration: none;
    transition: 0.2s ease;
    outline: none;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }

    &:hover,
    &:focus {
        outline: 0;
        color: var(--whiteColor);
    }
}

@mixin avatusButtonFontStyles {
    font-family: $fontFamilySemiBold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.14px;
    outline: 0;

    &:hover,
    &:focus {
        outline: 0;
    }
}

@mixin avatusButtonStyles {
    @include avatusButtonFontStyles;
    display: block;
    width: 100%;
    min-height: 45px;
    margin: 0;
    padding: 17px 0 17px 0;

    @media screen and (min-width: $breakpoint_768) {
        padding: 20px 0 20px 0;
    }

    border: none;
    border-radius: 20px;
    background-color: var(--avatusColor);
    color: var(--whiteColor);
    text-align: center;
    text-decoration: none;
    transition: all $transitionStyleButton;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: none;
        background-color: var(--avatusColorDark);
        text-decoration: none;
    }

    &:disabled {
        background-color: var(--greyColor);
        color: var(--whiteColor);
        cursor: not-allowed;
        box-shadow: none;
    }

    @media screen and (min-width: $breakpoint_768) {
        min-height: 65px;
    }
}

@mixin avatusButtonStylesPrimary {
    @include avatusButtonStyles;

    background-color: $successColor;
    color: var(--whiteColor);

    &:hover,
    &:focus {
        background-color: $successColor;
        outline: none;
    }

    &:disabled {
        background-color: var(--greyColor);
        color: var(--whiteColor);
    }
}

@mixin avatusInputStyles {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--avatusColor);
    border-radius: 0;
    font-family: 'PoppinsRegular' !important;

    @include placeholder {
        color: var(--greyColorTwo);
        font-size: 14px;
    }

    transition: border-bottom-color $transitionStyle;

    &:hover,
    &:focus {
        border-bottom-color: var(--matterColor);
        outline: none;
    }

    @media screen and (min-width: $breakpoint_768) {
        padding: 4px 0 6px 0;
    }
}

@mixin avatusSelectStyles {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    appearance: none;
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--avatusColor);
    border-radius: 0;
    background-size: 16px 16px;
    background-position: center right;
    cursor: pointer;
    transition: border-bottom-color $transitionStyle;

    &:hover,
    &:focus {
        border-bottom-color: var(--matterColor);
        outline: none;
    }

    @media screen and (min-width: $breakpoint_768) {
        padding: 4px 0 6px 0;
    }
}

@mixin avatusModalRootStyles {
    flex-grow: 1;
    display: flex;

    @media screen and (min-width: $breakpoint_768) {
        justify-content: center;
        align-items: flex-start;
    }
}

@mixin avatusModalBaseStyles {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    border-bottom: none;

    @media screen and (max-width: $breakpoint_320) {
        padding: 0 15px 6px;
    }
}

@mixin avatusModalInMobileBaseStyles {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;
    background-color: var(--whiteColor);
    border-radius: 20px;

    @media screen and (min-width: $breakpoint_992) {
        flex-basis: 480px;
        flex-grow: 0;
        height: 100%;
        padding: 0;
        background-color: transparent;
        border-radius: 0;
    }
}

@mixin avatusModalTitleStyles {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-family: $fontFamilyBold;

    @media screen and (min-width: $breakpoint_768) {
        font-family: $fontFamilySemiBold;
        line-height: 40px;
        letter-spacing: -0.9px;
        margin: 0;
    }
}

@mixin avatusModalParagraphStyles {
    margin: 18px 0 0 0;
    font-weight: $fontWeightLight;

    @media screen and (min-width: $breakpoint_768) {
        margin: 24px 0 0 0;
    }
}

@mixin avatusModalBottomWrapper {
    text-align: center;
    margin-top: 60px;
    align-self: stretch;

    @media screen and (min-width: $breakpoint_768) {
        margin-top: 96px;
    }
}

@mixin avatusModalHelperText {
    @include avatusTinyFontStyles;
    color: var(--matterColorAnti);
    margin: 0;
    line-height: 24px;

    @media screen and (min-width: $breakpoint_768) {
        margin: 0;
    }
}

@mixin avatusModalHelperLink {
    @include avatusTinyFontStyles;
    color: var(--matterColor);
    margin: 0;

    @media screen and (min-width: $breakpoint_768) {
        margin: 0;
    }
}

@mixin avatusModalCloseStyles {
    @include position(absolute, 25px, 0, auto, auto);
    cursor: pointer;
    z-index: $zIndexModal + 1;

    /* Safari didn't know how to baseline-align svg icon and text */
    @include flexAlign;
    width: auto;
    margin: 0 20px;
    border: 0;
    background-color: transparent;
    color: var(--whiteColor);
    width: 28px;
    height: 28px;
    background-color: var(--closeBg);
    padding: 7px;

    @media screen and (max-width: $breakpoint_767) {
        top: -3px;
    }

    @media screen and (max-width: $breakpoint_320) {
        top: -1px;
        width: 20px;
        height: 20px;
        padding: 5px;
    }

    img {
        max-width: 100%;
        height: 100%;
    }

    &:enabled:hover {
        color: var(--whiteColor);
        box-shadow: none;
        text-decoration: none;
    }

    &:enabled:active {
        color: var(--whiteColor);
    }
}

@mixin avatusModalIconStyles {
    height: 48px;
    margin-bottom: 12px;

    @media screen and (min-width: $breakpoint_768) {
        height: 64px;
        margin-bottom: 23px;
    }
}

@mixin avatusModalCloseText {
    @include avatusH6FontStyles;
    margin: -2.5px 0 0 0;

    @media screen and (min-width: $breakpoint_768) {
        margin-top: -1px;
    }
}

@mixin avatusModalCloseIcon {
    display: inline-block;
    margin-left: 8px;
    padding: 2px 0 4px;
    box-sizing: content-box;
}

@mixin avatusModalErrorStyles {
    @include avatusH4FontStyles;
    margin-top: 24px;
    color: $failColor;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin imgContain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@mixin imgCover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin text-truncate($line, $height: "") {
    display: block !important;
    display: -webkit-box !important;
    @include line-clamp($line);
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: $height;
    min-height: $height;
}

@mixin line-clamp($line) {
    -webkit-line-clamp: $line;
    -moz-line-clamp: $line;
    -ms-line-clamp: $line;
    -o-line-clamp: $line;
    line-clamp: $line;
}

@mixin visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    /* IE6, IE7 */
    white-space: nowrap;
}

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin inlineFlexCenter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@mixin flexAlign() {
    @include flexbox;
    align-items: center;
}

@mixin falign($value) {
    @include flexbox;
    -webkit-box-align: $value;
    align-items: $value;
}

@mixin fpos($a-item, $j-align) {
    @include flexbox;
    align-items: $a-item;
    justify-content: $j-align;
}

@mixin flexCenter() {
    @include flexbox;
    align-items: center;
    justify-content: center;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

// Single-Axis Scroller
@mixin custom-scroller($axis, $brodness: 5px) {
    @if ($axis=="x") {
        overflow-x: auto;
        overflow-y: hidden;
    }

    @if ($axis=="y") {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        width: $brodness;
        height: $brodness;
    }

    &::-webkit-scrollbar-track {
        width: $brodness;
        height: $brodness;
        box-shadow: none !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent !important;
        box-shadow: none !important;
        width: $brodness;
        height: $brodness;
    }
}

@mixin scroller($axis, $brodness: 4px) {
    @if ($axis=="x") {
        overflow-x: auto;
        overflow-y: hidden;
    }

    @if ($axis=="y") {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        width: $brodness;
        height: $brodness;
    }

    &::-webkit-scrollbar-track {
        width: $brodness;
        height: $brodness;
        background-color: var(--scrollTrack) !important;
        box-shadow: none !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scrollThumb) !important;
        box-shadow: none !important;
        width: $brodness;
        height: $brodness;
    }
}