@import "assets/styles/sanitize.css";
@import "assets/styles/propertySets.scss";
@import "assets/styles/typography.scss";
@import "assets/styles/theme.scss";

::selection {
  background: var(--avatusColor);
  color: $white;
}

::-moz-selection {
  background: var(--avatusColor);
  color: $white;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--blackColor);
  -webkit-box-shadow: inset 0 0 6px var(--blackColor);
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--lightGreyColor);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: inset 0 0 6px var(--bgColorTwo);
  -webkit-box-shadow: inset 0 0 6px var(--bgColorTwo);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: var(--lightGreyColor);
}

html {
  scrollbar-color: var(--lightGreyColor) var(--blackColor);
  scroll-behavior: smooth;
}

body {
  font: 16px/1.5 $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  background-color: var(--blackColorTwo);
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

a {
  @include avatusLinkStyles;
  outline: none;
  cursor: pointer;
}

h1 {
  @include avatusH1FontStyles;
}

h2 {
  @include avatusH2FontStyles;
}

h3 {
  @include avatusH3FontStyles;
}

h4 {
  @include avatusH4FontStyles;
}

h5 {
  @include avatusH5FontStyles;
}

h6 {
  @include avatusH6FontStyles;
}

button {
  outline: none;
  cursor: pointer;
  font-family: $fontFamily;
  padding: 0;
  border: none;
}

select {
  @include avatusSelectStyles;
}

textarea {
  @include avatusInputStyles;
  box-sizing: content-box;
  padding: 5px 0 8px 0;

  @include placeholder() {
    padding: 0 0 4px 0;
  }
}

.fontsLoaded {

  body,
  button {
    font-family: $fontFamily;
  }
}

.app_wrapper {
  position: relative;
  min-height: 100%;
  padding: 80px 0 20px;
  width: 100%;
  max-width: 1640px;
  margin: 0 auto;
}

.custom_container {
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  height: 100%;

  @media screen and (max-width: $breakpoint_1366) {
    max-width: 95%;
  }

  @media screen and (max-width: $breakpoint_1024) {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }

  @media screen and (max-width: $breakpoint_576) {
    padding: 0 15px;
  }

  .custom_container {
    max-width: 100%;
    padding: 0;

    &.avatarsteps_container {
      @media screen and (max-width: $breakpoint_767) {
        padding: 15px 10px 100px;
      }
    }
  }
}

.avatus_container {
  width: 90%;
  margin: 0 auto;
  position: relative;
  height: 100%;

  @media screen and (max-width: $breakpoint_768) {
    width: 95%;
  }
}

.black_wrapper {
  background-color: var(--blackColorTwo);
  height: 100%;
}

.section_wrapper {
  padding-bottom: 30px;
  position: relative;

  @media screen and (max-width: $breakpoint_1024) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: $breakpoint_576) {
    padding-bottom: 16px;
  }

  &:nth-last-of-type(1) {
    padding-bottom: 0;
  }
}

.bottom_section {
  margin-bottom: 30px;

  @media screen and (max-width: $breakpoint_650) {
    margin-bottom: 15px;
  }
}

.form_field {
  margin-bottom: 25px;
}

.form_field_margin {
  margin-bottom: 20px;

  &.onboard_form_field {
    @media screen and (max-width: $breakpoint_768) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: $breakpoint_480) {
      margin-bottom: 22px;
    }
  }
}

.text_field,
.relative {
  position: relative;
}

.light_theme_img {
  display: none !important;
}

.title {
  font: 22px/1.4 $fontFamilySemiBold;
  color: var(--whiteColor);
  letter-spacing: 1.04px;
  margin-bottom: 12px;
  display: block;

  @media screen and (max-width: $breakpoint_768) {
    font-size: 26px;
    margin-bottom: 14px;
    max-width: 85%;
  }

  @media screen and (max-width: $breakpoint_650) {
    font-size: 18px;
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.sub_title {
  font: 24px/1.88 $fontFamilyMedium;
  color: var(--whiteColor);
  letter-spacing: 0.6px;
  margin: 0;
  display: block;
  position: relative;

  @media screen and (max-width: $breakpoint_1366) {
    font-size: 20px;
  }

  @media screen and (max-width: $breakpoint_950) {
    font-size: 18px;
  }

  @media screen and (max-width: $breakpoint_650) {
    font-size: 16px;
  }

  @media screen and (max-width: $breakpoint_320) {
    font-size: 14px;
  }
}

.xs_title {
  font: 16px/1.33 $fontFamilySemiBold;
  color: var(--whiteColor);
  letter-spacing: 0.1px;
  margin: 0;
  display: block;
  position: relative;

  @media screen and (max-width: $breakpoint_1366) {
    font-size: 14px;
  }

  @media screen and (max-width: $breakpoint_767) {
    font-size: 13px;
  }

  @media screen and (max-width: $breakpoint_360) {
    font-size: 12px;
  }
}

.title_abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 18px;
  padding: 20px 20px 0;
  background-color: var(--darkGreyColor);
  z-index: 1;

  @media screen and (max-width: $breakpoint_1180) {
    padding: 15px 15px 0;
  }
}

.common_para {
  font-size: 14px;
  line-height: 1.43;
  color: var(--lightGreyColor);
  margin: 0 0 30px 0;

  @media screen and (max-width: $breakpoint_650) {
    font-size: 12px;
    margin-bottom: 20px;
  }

  &.mb-16 {
    margin-bottom: 16px;
  }
}

.landing_title {
  font-size: 1.5vw;
  letter-spacing: 7.99px;
  line-height: normal;
  margin-top: 0;

  @media screen and (max-width: $breakpoint_1920) {
    font-size: 34px;
  }

  @media screen and (max-width: $breakpoint_1600) {
    font-size: 32px;
  }

  @media screen and (max-width: $breakpoint_1440) {
    font-size: 30px;
    letter-spacing: 7px;
  }

  @media screen and (max-width: $breakpoint_1024) {
    font-size: 28px;
    letter-spacing: 6px;
  }

  @media screen and (max-width: $breakpoint_992) {
    font-size: 24px;
    letter-spacing: 5px;
  }

  @media screen and (max-width: $breakpoint_820) {
    font-size: 22px;
    letter-spacing: 4px;
  }

  @media screen and (max-width: $breakpoint_576) {
    font-size: 20px;
    letter-spacing: 2px;
  }
}

.landing_para,
.landing_para p {
  font-size: 0.9vw;
  line-height: normal;
  letter-spacing: 1.2px;

  @media screen and (max-width: $breakpoint_1920) {
    line-height: 26px;
    font-size: 15px;
  }

  @media screen and (max-width: $breakpoint_1800) {
    font-size: 14px;
    line-height: 24px;
  }

  @media screen and (max-width: $breakpoint_1440) {
    font-size: 13px;
    letter-spacing: 0.8px;
  }

  @media screen and (max-width: $breakpoint_576) {
    font-size: 12px;
    letter-spacing: 0.5px;
  }
}

.landing_back_btn {
  @include flexAlign;
  letter-spacing: 0.26px;
  color: $white;
  font-size: 20px;
  padding: 60px 0;
  margin-left: -15px;
  cursor: pointer;
  position: fixed;
  white-space: nowrap;
  top: 0;

  @media screen and (min-width: 1921px) and (min-height: 1081px) {
    font-size: 1vw;
  }

  @media screen and (max-width: $breakpoint_1920) {
    font-size: 20px;
  }

  @media screen and (max-width: $breakpoint_1800) {
    font-size: 16px;
  }

  @media screen and (max-width: $breakpoint_1440) {
    font-size: 15px;
  }

  @media screen and (max-width: $breakpoint_1279) {
    padding-bottom: 20px;
    padding-top: 25px;
  }

  @media screen and (max-width: $breakpoint_1024) {
    position: fixed;
    top: 65px;
    padding: 0;
  }

  @media screen and (max-height: $breakpoint_820) {
    padding: 30px 0;
  }

  img {
    width: 50px;
    height: 50px;

    @media screen and (min-width: 1921px) and (min-height: 1081px) {
      width: 2vw;
      height: 2vw;
    }

    @media screen and (max-width: $breakpoint_1919) {
      width: 45px;
      height: 45px;
    }

    @media screen and (max-width: $breakpoint_1398) {
      width: 48px;
      height: 48px;
    }

    @media screen and (max-width: $breakpoint_1279) {
      width: 45px;
      height: 45px;
    }

    @media screen and (max-width: $breakpoint_820) {
      width: 40px;
      height: 40px;
    }
  }

  span {
    @media screen and (max-width: $breakpoint_1024) {
      display: none;
    }
  }
}

.filter_btn {
  border: solid 0.6px var(--blackColorFour);
  padding: 6px 16px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 1.2px;
  color: var(--whiteColor);
  margin-bottom: 0;
  display: inline-block;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  &.active {
    border-color: var(--avatusColor);
  }
}

.light_hover_img {
  display: none;
}

.back_btn {
  position: relative;
  padding: 0 0 0 30px;
  color: var(--whiteColor);
  letter-spacing: 0.4px;
  font: normal normal normal 16px/1 $fontFamily;
  margin-bottom: 50px;
  cursor: pointer;
  display: inline-block;

  @media screen and (max-width: $breakpoint_650) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: $breakpoint_576) {
    font-size: 14px;
    padding: 0 0 0 22px;
  }

  @media screen and (max-width: $breakpoint_480) {
    padding: 0 0 0 18px;
  }

  &::before {
    content: "";
    width: 32px;
    height: 32px;
    @include position(absolute, 50%, auto, auto, -10px);
    transform: translateY(-50%);
    background: url("../images/ic-back.svg") center no-repeat;
    background-size: contain;

    @media screen and (max-width: $breakpoint_650) {
      left: -8px;
    }

    @media screen and (max-width: $breakpoint_480) {
      left: -11px;
    }
  }

  span {
    @media screen and (max-width: $breakpoint_1366) {
      font-size: 13px;
    }

    @media screen and (max-width: $breakpoint_767) {
      font-size: 12px;
    }
  }
}

.pwd_tips {
  li {
    font-size: 12px;
    color: var(--lightColor);
    line-height: 1.5;
    margin-bottom: 8px;
    list-style-image: url("../../assets/images/marker-grey.svg");

    &.green {
      list-style-image: url("../../assets/images/marker-tick-green.svg");
      color: $lightGreen;
    }

    &.red {
      list-style-image: url("../../assets/images/marker-red.svg");
      color: $failColor;
    }
  }
}

.modal_content {
  .paraText {
    margin: 0 auto 30px;
    text-align: center;
    max-width: 70%;
    word-break: break-word;
  }

  .action_btns_wrapper {
    margin: 0 -10px;
    @include flexAlign;

    .btn_wrap {
      width: 50%;
      padding: 0 10px;
    }
  }

  &.no_data_found {
    min-height: 60px;
    @include flexCenter;

    .paraText {
      margin-bottom: 0;
    }
  }
}

.m_title {
  font: 20px/1.33 $fontFamilyMedium;
  border-bottom: solid 0.5px var(--borderColor);
  padding: 0 0 18px;
  text-align: center;
  margin-bottom: 20px;
  @include flexCenter;

  @media screen and (max-width: $breakpoint_767) {
    font-size: 18px;
  }

  @media screen and (max-width: $breakpoint_360) {
    font-size: 16px;
  }

  @media screen and (max-width: $breakpoint_320) {
    font-size: 14px;
  }

  .txt {
    @media screen and (max-width: $breakpoint_360) {
      max-width: 70%;
      display: inline-block;
      padding-right: 5px;
    }
  }

  &.b_none {
    border: none;
  }
}

.m_content {
  padding-top: 0;
  max-height: 250px;
  overflow-y: auto;
}

.txt_info {
  text-align: center;

  p {
    color: var(--whiteColor);
  }
}

.success_popup {
  text-align: center;
  padding-bottom: 12px;

  @media screen and (max-width: $breakpoint_480) {
    padding-bottom: 10px;
  }

  .img_wrap {
    position: relative;
    width: 110px;
    height: 110px;
    border: 2px solid var(--blackColorTwo);
    margin: 10px auto 24px;

    img {
      max-width: 100%;
    }

    .checkmark {
      position: absolute;
      bottom: -6px;
      right: -15px;
    }
  }

  .tick_icon {
    margin: 20px 0 15px;
  }

  .common_para {
    max-width: 300px;
    margin: 0 auto 34px;

    &.mb-45 {
      margin-bottom: 45px;
    }

    &.mb-8 {
      margin-bottom: 8px;
    }

    &.max_290 {
      max-width: 290px;
    }
  }

  >span,
  .sub_title {
    font-size: 22px;
    margin-bottom: 14px;

    @media screen and (max-width: $breakpoint_767) {
      font-size: 18px;
      margin-bottom: 12px;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  .radio_group {
    margin-bottom: 18px;
    padding: 0 10px;
  }

  .action_btns_wrapper {
    margin: 0 -10px;
    @include flexAlign;

    .btn_wrap {
      width: 50%;
      padding: 0 10px;
    }
  }

  .btn_topup {
    @include inlineFlexCenter;
  }
}

.payout_success {
  .m_title {
    border-bottom: none;
    margin-bottom: 0;
  }

  .common_para {
    max-width: 260px;
    color: var(--whiteColor);
  }
}

.sm_popup {
  text-align: center;

  .tick_icon {
    margin: 20px 0 15px;
  }

  >span {
    font-size: 22px;

    @media screen and (max-width: $breakpoint_1024) {
      font-size: 20px;
    }

    @media screen and (max-width: $breakpoint_767) {
      font-size: 18px;
    }

    @media screen and (max-width: $breakpoint_576) {
      font-size: 16px;
    }
  }

  button {
    max-width: 163px;
    padding: 9px 10px;
    font-family: $fontFamilyMedium;

    &.outline_btn {
      margin-left: 20px;
    }
  }
}

.send_fund_form {
  .coin_img {
    position: absolute;
    left: 15px;
    top: 20px;
    width: 16px;
    z-index: 1;
  }

  .MuiOutlinedInput-input {
    font: normal normal 16px/16px $fontFamily !important;
    color: var(--countColor) !important;
    padding-left: 42px;
  }

  .MuiFormLabel-root {
    margin-left: 30px;

    &.Mui-focused {
      margin-left: 0;
    }
  }
}

.web_cam {
  .cam {
    height: $breakpoint_360;
    margin-bottom: 20px;

    img,
    video {
      @include imgCover;
      min-height: 100%;
    }
  }

  .capture_btn {
    min-height: auto;
    margin: auto;
    @include flexCenter;
  }
}

.form_label {
  font: 12px/16px $fontFamily;
  margin: 0 0 8px 0;
  color: var(--lightGreyColor);
  display: block;

  &.white {
    color: var(--whiteColor);
  }
}

.show_label {
  font: 14px/18px $fontFamily;
  margin: 0 0 8px 0;
  color: var(--lightGreyColor);
  display: block;
  line-height: 1.5;

  @media screen and (max-width: $breakpoint_950) {
    font-size: 13px;
  }

  .show_label_text {
    font-size: 12px;
  }

  &.white {
    color: var(--whiteColor);
  }

  &.onboard_label {
    margin-bottom: 35px;

    @media screen and (max-width: $breakpoint_768) {
      font-size: 15px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 14px;
    }
  }
}

.form_wrapper {
  padding: 30px 0;
  width: 100%;
  max-width: 487px;
  margin: 0 auto 0 0;
  @include position(absolute, 0, auto, 0, 67px);
  @include flexAlign;

  @media screen and (max-height: $breakpoint_820) {
    align-items: flex-start;
  }

  @media screen and (max-width: $breakpoint_1366) {
    max-width: 450px;
  }

  @media screen and (max-width: $breakpoint_1180) {
    left: 0;
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (max-width: $breakpoint_1100) {
    padding: {
      left: 30px;
      right: 30px;
    }
  }

  @media screen and (max-width: $breakpoint_768) {
    position: relative;
    padding: 5px 25px 25px;
  }

  @media screen and (max-width: $breakpoint_375) {
    padding: {
      left: 15px;
      right: 15px;
    }
  }

  .login_form {
    width: 100%;
    align-self: center;

    @media screen and (max-height: 500px) {
      align-self: flex-start;
    }
  }
}

.link {
  position: relative;
  font: normal normal normal 14px/14px $fontFamily;
  color: var(--whiteColor);
  letter-spacing: 0.3px;
  cursor: pointer;

  @media screen and (max-width: $breakpoint_950) {
    font-size: 13px;
  }

  @media screen and (max-width: $breakpoint_320) {
    font-size: 11px;
  }

  &:after {
    content: "";
    @include position(absolute, auto, auto, 0, 0);
    width: 0;
    height: 1px;
    background: var(--whiteColor);
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }

  &.onboard_link {
    @media screen and (max-width: $breakpoint_768) {
      font-size: 15px;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 14px;
    }
  }
}

.buttonLink {
  border: none;
}

button,
a {
  &.fill_red_btn {
    font-family: $fontFamilyMedium;
    background-color: var(--avatusColor);
    letter-spacing: 0.3px;
    color: $white;
    padding: 6px 10px;
    transition: all ease-in 0.3s;
    display: inline-block;
    font-size: 14px;
    width: 100%;
    min-height: 40px;
    max-width: 320px;
    border: none;
    white-space: nowrap;

    @media screen and (max-width: $breakpoint_1366) {
      font-size: 13px;
    }

    @media screen and (max-width: $breakpoint_768) {
      min-height: 36px;
    }

    @media screen and (max-width: $breakpoint_650) {
      font-size: 12px;
    }

    &.disable_btn {
      background-color: var(--greyColor);
      cursor: not-allowed;
    }

    &.full_btn {
      max-width: 370px;
    }

    &:hover {
      background-color: var(--avatusColor) !important;
    }
  }

  &.outline_btn {
    background-color: transparent;
    border: 1px solid var(--textColor);
    letter-spacing: 0.3px;
    color: $white;
    padding: 5px 10px;
    transition: all ease-in 0.3s;
    display: inline-block;
    font-size: 14px;
    width: 100%;
    min-height: 40px;
    max-width: 320px;
    font-family: $fontFamilyMedium;

    @media screen and (max-width: $breakpoint_1366) {
      font-size: 13px;
    }

    @media screen and (max-width: $breakpoint_768) {
      min-height: 36px;
    }

    @media screen and (max-width: $breakpoint_650) {
      font-size: 12px;
    }

    &.disable_btn {
      background-color: $disabledBg;
      color: $disabledColor;
      cursor: not-allowed;

      &:hover {
        &::before {
          border-image: none;
        }
      }
    }
  }

  &.btn {
    max-width: 163px;

    &.outline_btn {
      margin-left: 20px;
    }
  }

  &.sm_btn {
    width: 115px;
    min-height: 33px;
  }

  &.btn_onboard {
    @media screen and (max-width: $breakpoint_768) {
      max-width: 100%;
      height: 60px;
      font-size: 16px;
    }

    @media screen and (max-width: $breakpoint_480) {
      height: 52px;
      font-size: 14px;
    }
  }

  &.btn_lg {
    @media screen and (max-width: $breakpoint_950) {
      height: 50px;
    }

    @media screen and (max-width: $breakpoint_480) {
      height: 40px;
    }
  }

  &.btn_max {
    max-width: 220px;
  }

  &.only_child {
    max-width: 163px;
    margin: 0 auto;
  }

  &.btn_full {
    @media screen and (max-width: $breakpoint_480) {
      max-width: 100%;
    }
  }
}

.tab_btn {
  width: 50%;
  letter-spacing: 2px;
  height: 40px;
  font-size: 14px;
  font-family: $fontFamily;
  font-weight: $fontWeightLight;
  background: var(--blackColorFour);
  color: var(--whiteColor);
  clip-path: polygon(20px 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      100% 50%,
      calc(100% - 20px) 100%,
      20px 100%,
      0 50%,
      0 50%);

  &.tab_selected {
    background-color: $avatusColor;
    z-index: 1;
    width: 58%;
  }
}

.mobile_hidden {
  @media screen and (max-width: $breakpoint_767) {
    display: none !important;
  }
}

.mobile_padding {
  @media screen and (max-width: $breakpoint_767) {
    padding: 10px 0 0 !important;
  }
}

.h_100 {
  height: 100%;
}

.mw_163 {
  max-width: 163px;
  margin: 0 auto;
}

.number_highlight {
  font-family: $fontFamilySemiBold;
}

.btn-effect {
  position: relative;

  &:before {
    @include position(absolute, 50%, -7px, auto, auto);
    width: 90%;
    height: 90%;
    background: transparent;
    border: 1px solid transparent;
    content: "";
    transform: translateY(-50%);
    -moz-transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    border-left: 0 solid transparent;
  }

  &:after {
    @include position(absolute, 50%, auto, auto, -7px);
    width: 90%;
    height: 90%;
    background: transparent;
    border: 1px solid transparent;
    content: "";
    transform: translateY(-50%);
    -moz-transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    border-right: 0 solid transparent;
  }

  &:hover {
    &:before {
      width: 95%;
      border-image: linear-gradient(0deg,
          hsla(0, 0%, 100%, 0) 50%,
          hsla(0, 0%, 100%, 0.278431) 102%,
          hsla(0, 0%, 100%, 0) 0);
      border-width: 1px;
      border-image-slice: 1;
      height: 125%;
      border-left: 0 solid transparent;
    }

    &:after {
      width: 95%;
      border-image: linear-gradient(180deg,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 100%, 0.25098) 102%,
          hsla(0, 0%, 100%, 0) 0);
      border-width: 1px;
      border-image-slice: 1;
      height: 125%;
      border-right: 0 solid transparent;
    }
  }
}

@-webkit-keyframes ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.ripple-out {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  -webkit-transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px var(--avatusColor);
  border-color: var(--effectColorOne);
  position: relative;

  &:before {
    content: "";
    @include position(absolute, 0, 0, 0, 0);
    border: $effectColor solid 1px;
    border-radius: 50%;
    animation-duration: ripple-out 1s infinite;
    -webkit-animation-duration: ripple-out 1s infinite;
    animation: ripple-out 1s infinite;
  }
}

input,
textarea {
  outline: none;
  padding: 15px 18px;
  width: 100%;
  color: var(--greyColorTwo);
  font-size: 14px;
  font-family: $fontFamily;
  letter-spacing: 0.14px;
  background-color: transparent;
  letter-spacing: 0.5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

  @include placeholder {
    font-size: 12px;
    font-family: $fontFamily;
  }
}

input[autocomplete="off"] {

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }
}

input[type="range"] {
  &::-webkit-slider-thumb {
    width: 0;
    height: 0;
    background: transparent;
    background-size: contain;
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
    cursor: pointer;
    border: none;
  }

  &::-moz-range-thumb {
    width: 0;
    height: 0;
    background: transparent;
    background-size: contain;
    appearance: none;
    -moz-appearance: none;
    margin: 0;
    cursor: pointer;
    border: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  font-family: $fontFamily;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--whiteColor);
}

.detect_icon {
  @include position(absolute, 50%, 15px, auto, auto);
  transform: translateY(-50%);
  padding: 0;
  border: none;
  @include flexAlign;
}

.placeholder_otp {
  input {
    @include placeholder() {
      color: var(--textColor);
      font-size: 70px;
      opacity: 1;
    }
  }
}

.show_more {
  font-size: 12px;
  font-family: $fontFamilySemiBold;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: var(--avatusColor);
  min-height: auto;
  position: relative;
  width: 100%;
  @include flexCenter;

  &:before {
    content: "";
    @include position(absolute, 50%, 0, auto, auto);
    transform: translateY(-50%);
    width: 42%;
    height: 0.5px;
    background-color: var(--greyColor);

    @media screen and (max-width: $breakpoint_768) {
      width: 35%;
    }
  }

  &:after {
    content: "";
    @include position(absolute, 50%, auto, auto, 0);
    transform: translateY(-50%);
    width: 42%;
    height: 0.5px;
    background-color: var(--greyColor);

    @media screen and (max-width: $breakpoint_768) {
      width: 35%;
    }
  }
}

.no_data {
  position: relative;
  flex-flow: column;
  width: 100%;
  @include flexCenter;
  height: 100%;
  max-height: calc(100vh - 200px);

  .no_img {
    margin: 0 auto 20px;
    max-width: 100%;

    img {
      width: 100%;
      height: 100%;
      display: block;

      @media screen and (max-width: $breakpoint_576) {
        max-width: 150px;
      }
    }
  }

  .no_data_text {
    text-align: center;
    color: var(--lightColor);
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.16px;

    @media screen and (max-width: $breakpoint_767) {
      font-size: 14px;
    }

    @media screen and (max-width: $breakpoint_320) {
      font-size: 12px;
    }
  }

  &.success_data {
    @media screen and (max-width: $breakpoint_768) {
      height: auto;
      padding: 50px 10px;
    }

    .no_img {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      padding: 10px;
      margin: 0 auto 32px;
      background: url("../images/channel-success-border.svg") center no-repeat;
      background-size: contain;

      img {
        border-radius: 50%;
      }
    }

    .no_data_text {
      color: var(--lightGreyColor);
      font-size: 14px;

      @media screen and (max-width: $breakpoint_480) {
        font-size: 12px;
      }

      &.sub_title {
        font: 24px/1.88 $fontFamily;
        color: var(--whiteColor);
      }
    }
  }

  &.event_success_data {
    @include position(absolute, 0, 0, 0, 0);

    .event_created {
      font-family: $fontFamilySemiBold;
      font-size: 18px;
      letter-spacing: 0.6px;

      @media screen and (max-width: $breakpoint_1366) {
        font-size: 16px;
      }
    }
  }

  &.no_data_for_channel_posts {
    height: 100%;
  }

  &.no_data_for_events {
    max-height: calc(100vh - 238px);
  }

  &.no_data_for_reactions {
    min-height: 160px;
  }

  &.no_data_for_wishlist {
    max-height: calc(100vh - 330px);

    @media screen and (max-height: $breakpoint_730) {
      max-height: calc(100vh - 270px);
    }

    @media screen and (max-height: $breakpoint_767) {
      height: 240px;
      max-height: unset;
    }
  }

  &.no_data_for_settings_report {
    height: 100%;
  }

  &.no_data_for_notification {
    height: 400px;
  }

  &.no_data_for_contacts {
    max-height: 400px;
  }

  &.no_data_for_explore_posts {
    max-height: calc(100vh - 238px);
  }

  &.no_data_for_explore {
    max-height: calc(100vh - 238px);
  }

  &.no_data_for_transaction {
    max-height: calc(100vh - 270px);
  }

  &.no_data_for_purchase {
    max-height: calc(100vh - 410px);
  }

  &.no_data_for_post_home {
    @include position(absolute, 0, 0, 0, 0);
    max-height: calc(100% - 30px);
  }

  &.no_data_for_notifications {
    min-height: 400px;
    padding: 40px 10px 60px;

    @media screen and (max-width: $breakpoint_576) {
      min-height: auto;
    }
  }

  &.no_data_for_follow {
    padding: 40px 0;

    @media screen and (min-height: $breakpoint_730) {
      min-height: 300px;
    }
  }

  &.no_data_for_saved_post {
    height: calc(100vh - 170px);
  }

  &.no_suggestions {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &.no_message {
    max-height: calc(100% - 140px);
  }
}

.empty_box {
  @include flexCenter;
  flex-flow: column;
  padding: 15px 30px;
  position: relative;
  min-width: 340px;
  min-height: 160px;

  .empty_img {
    width: 45px;
    height: 45px;

    @media screen and (max-width: $breakpoint_1024) {
      width: 35px;
      height: 35px;
    }

    @media screen and (max-width: $breakpoint_480) {
      width: 30px;
      height: 30px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.post_img {
      width: 70px;
      height: 48px;
    }
  }

  .empty_txt {
    color: var(--emptyStateColor);
    max-width: 260px;
    margin: 22px auto 0;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.6px;
    line-height: 22px;
    font-family: $fontFamilyMedium;

    @media screen and (max-width: $breakpoint_1366) {
      font-size: 14px;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 13px;
      margin-top: 20px;
    }

    @media screen and (max-width: $breakpoint_320) {
      font-size: 12px;
      margin-top: 15px;
    }
  }

  &.empty_notification_box {
    min-width: auto;
    min-height: 120px;

    .empty_img {
      width: 25px;
      height: 25px;

      @media screen and (max-width: $breakpoint_320) {
        width: 15px;
        height: 15px;
      }
    }

    .empty_txt {
      font-size: 12px;
      margin-top: 15px;
    }
  }

  &.empty_contact_box {
    min-width: 100%;
    min-height: 120px;

    .empty_img {
      width: 25px;
      height: 25px;
    }

    .empty_txt {
      margin-top: 8px;
    }
  }

  &.empty_gift_box {
    .empty_txt {
      font-size: 12px;
      line-height: normal;
      margin-top: 8px;
    }
  }

  &.empty_post_box {
    .empty_img {
      width: auto;
    }
  }

  &.empty_reaction_box {
    min-width: 90%;

    .empty_txt {
      max-width: 90%;
    }
  }

  &::before,
  &::after {
    width: 20px;
    min-height: 100%;
    background-image: url("../images/empty_box_bg.svg");
    background-size: contain;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &::before {
    left: 0;
  }

  &::after {
    transform: rotateY(180deg);
    right: 0;
  }
}

.no_events_btn {
  pointer-events: none;
}

.hide {
  display: none;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  visibility: visible;
}

.hide_scroll {
  overflow: hidden !important;
}

.line-1 {
  @include text-truncate(1);
}

.line-2 {
  @include text-truncate(2);
}

.title_head {
  margin-bottom: 16px;
}

.heading_wrapper {
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: $breakpoint_480) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: $breakpoint_320) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: $breakpoint_280) {
    margin-bottom: 10px;
  }
}

.input_search_focussed {
  .recent_search {
    opacity: 1;

    visibility: visible;

    @media screen and (max-width: $breakpoint_480) {
      width: calc(100vw - 100px);
      top: 20px;
      right: 0;
    }
  }
}

.recent_search {
  @include position(absolute, 48px, auto, auto, auto);
  z-index: 999;
  width: 100%;
  padding: 16px 16px 10px;
  box-shadow: 0 0 20px 0 var(--bgColorThree);
  border: solid 1px var(--greyColorThree);
  background-color: var(--darkGreyColor);
  min-height: 49px;
  max-height: 240px;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;

  @media screen and (max-width: $breakpoint_820) {
    top: 38px;
    padding: 12px 10px 10px;
  }

  .data_list {
    .data_list_item {
      margin: 0 -10px 5px;
      position: relative;
      padding: 8px 20px 8px 15px;
      @include fpos(center, space-between);

      button {
        font: 12px/1.5 $fontFamily;
        letter-spacing: 0.12px;
        color: var(--whiteColor);
        width: 93%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }

      .remove_search {
        @include flexAlign;
        cursor: pointer;
        width: auto;
        opacity: 0;
        visibility: hidden;

        svg {
          fill: var(--whiteColor);
        }
      }

      &:last-child {
        margin: 0 -10px;
      }

      &.active {
        button {
          color: var(--avatusColor);
        }
      }

      &:hover {
        background: url("../images/marker-red.svg") center no-repeat;
        background-size: cover;

        .remove_search {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .recent_clear {
    font-size: 11px;
    line-height: 1.45;
    letter-spacing: 0.28px;
    color: var(--lightGreyColor);
    margin-bottom: 12px;
    padding: 0 4px;

    .clear {
      color: var(--avatusColor);
      font: 11px/16px $fontFamily;

      &::after {
        background-color: var(--avatusColor);
      }
    }
  }

  .no_search {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.12px;
    color: var(--whiteColor);
    @include flexCenter;
  }
}

.sidenav {
  width: 23%;
  height: 100%;
  @include position(sticky, 110px, auto, auto, auto);
  max-width: 270px;

  @media screen and (max-width: $breakpoint_1180) {
    width: 22%;
  }

  @media screen and (max-width: $breakpoint_950) {
    width: 230px;
  }

  @media screen and (max-width: $breakpoint_1024) {
    top: 100px;
  }

  @media screen and (max-width: $breakpoint_890) {
    top: 90px;
  }

  @media screen and (max-width: $breakpoint_767) {
    display: none;
  }

  .inner {
    background-color: var(--darkGreyColor);
    padding: 24px 18px;
    overflow-y: auto;

    @media screen and (max-width: $breakpoint_1180) {
      padding: 20px 15px;
    }
  }

  .list_search {
    margin-bottom: 12px;
    position: relative;
  }

  &.active {
    @media screen and (max-width: $breakpoint_650) {
      left: 0;
    }
  }
}

.list_container {
  width: 77%;
  padding-left: 30px;
  min-height: 100%;
  flex: 1;

  @media screen and (max-width: $breakpoint_1180) {
    width: 78%;
  }

  @media screen and (max-width: $breakpoint_950) {
    width: calc(100% - 230px);
  }

  @media screen and (max-width: $breakpoint_1024) {
    padding-left: 20px;
  }

  @media screen and (max-width: $breakpoint_767) {
    width: 100%;
    padding-left: 0;
  }
}

.listing {
  li {

    a,
    .button {
      position: relative;
      font: 14px/16px $fontFamily;
      letter-spacing: 0.35px;
      color: var(--whiteColor);
      margin-bottom: 5px;
      padding: 11px 10px 11px 48px;
      display: block;
      transition: none;

      @media screen and (max-width: $breakpoint_1180) {
        font-size: 12px;
      }

      @media screen and (max-width: $breakpoint_1024) {
        padding-left: 40px;
      }

      &::before {
        content: "";
        @include position(absolute, 50%, auto, auto, 15px);
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-size: contain !important;

        @media screen and (max-width: $breakpoint_1024) {
          width: 20px;
          height: 20px;
          left: 12px;
        }
      }

      &.channel,
      &.my_purchase,
      &.my_account,
      &.my_coupons {
        &::before {
          background: url("../images/channel.svg") center no-repeat;
        }
      }

      &.my_channel {
        &::before {
          background: url("../images/my-channel.svg") center no-repeat;
        }
      }

      &.shop {
        &::before {
          background: url("../images/browse_shop.svg") center no-repeat;
        }
      }

      &.events {
        &::before {
          background: url("../images/browse_shop.svg") center no-repeat;
        }
      }

      &.my_wishlist {
        &::before {
          background: url("../images/my-wishlist.svg") center no-repeat;
        }
      }

      &.theme {
        &::before {
          background: url("../images/setting-theme.svg") center no-repeat;
        }
      }

      &.privacy {
        &::before {
          background: url("../images/setting-privacy.svg") center no-repeat;
        }
      }

      &.blocked {
        &::before {
          background: url("../images/setting-blocked.svg") center no-repeat;
        }
      }

      &.message {
        &::before {
          background: url("../images/setting-message.svg") center no-repeat;
        }
      }

      &.notification {
        &::before {
          background: url("../images/setting-notification.svg") center no-repeat;
        }
      }

      &.account {
        &::before {
          background: url("../images/setting-account.svg") center no-repeat;
        }
      }

      &.payment {
        &::before {
          background: url("../images/setting-payment.svg") center no-repeat;
        }
      }

      &.membership {
        &::before {
          background: url("../images/setting-membership.svg") center no-repeat;
        }
      }

      &.security {
        &::before {
          background: url("../images/setting-security.svg") center no-repeat;
        }
      }

      &.video {
        &::before {
          background: url("../images/setting-video.svg") center no-repeat;
        }
      }

      &.report {
        &::before {
          background: url("../images/setting-report.svg") center no-repeat;
        }
      }

      &.faq {
        &::before {
          background: url("../images/setting-faq.svg") center no-repeat;
        }
      }

      &.contact {
        &::before {
          background: url("../images/setting-contact.svg") center no-repeat;
        }
      }

      &.accessibility {
        &::before {
          background: url("../images/setting-accessibility.svg") center no-repeat;
        }
      }

      &.active,
      &.NamedLink_active,
      &:hover {
        background: url("../images/date-marker-black.svg") center no-repeat;
        background-size: cover;

        &.channel,
        &.my_purchase,
        &.my_account,
        &.my_coupons {
          &::before {
            background: url("../images/channel-selected.svg") center no-repeat;
          }
        }

        &.my_channel {
          &::before {
            background: url("../images/my-channel-selected.svg") center no-repeat;
          }
        }

        &.shop {
          &::before {
            background: url("../images/browse_shop_selected.svg") center no-repeat;
          }
        }

        &.my_wishlist {
          &::before {
            background: url("../images/my-wishlist-selected.svg") center no-repeat;
          }
        }

        &.theme {
          &::before {
            background: url("../images/setting-theme-selected.svg") center no-repeat;
          }
        }

        &.privacy {
          &::before {
            background: url("../images/setting-privacy-selected.svg") center no-repeat;
          }
        }

        &.blocked {
          &::before {
            background: url("../images/setting-blocked-selected.svg") center no-repeat;
          }
        }

        &.message {
          &::before {
            background: url("../images/setting-message-selected.svg") center no-repeat;
          }
        }

        &.notification {
          &::before {
            background: url("../images/setting-notification-selected.svg") center no-repeat;
          }
        }

        &.account {
          &::before {
            background: url("../images/setting-account-selected.svg") center no-repeat;
          }
        }

        &.payment {
          &::before {
            background: url("../images/setting-payment-selected.svg") center no-repeat;
          }
        }

        &.membership {
          &::before {
            background: url("../images/setting-membership-selected.svg") center no-repeat;
          }
        }

        &.security {
          &::before {
            background: url("../images/setting-security-selected.svg") center no-repeat;
          }
        }

        &.video {
          &::before {
            background: url("../images/setting-video-selected.svg") center no-repeat;
          }
        }

        &.report {
          &::before {
            background: url("../images/setting-report-selected.svg") center no-repeat;
          }
        }

        &.faq {
          &::before {
            background: url("../images/setting-faq-selected.svg") center no-repeat;
          }
        }

        &.contact {
          &::before {
            background: url("../images/setting-contact-selected.svg") center no-repeat;
          }
        }

        &.accessibility {
          &::before {
            background: url("../images/setting-accessibility-selected.svg") center no-repeat;
          }
        }
      }
    }
  }

  &.without_icons {
    li {

      a,
      .button {
        background: url("../images/date-marker-black.svg") center no-repeat;
        background-size: cover;
        padding: 12px 28px;
        text-align: center;
        color: var(--sidebarTextColor);
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;
        font-family: $fontFamilyMedium;

        @media screen and (max-width: $breakpoint_1180) {
          padding: 10px 28px;
        }

        &::before,
        &::after {
          display: none;
        }

        .badge_count {
          background-color: var(--avatusColor);
          color: var(--whiteColor);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          font-size: 10px;
          margin-left: 6px;
          @include inlineFlexCenter;
        }

        &.active,
        &.NamedLink_active,
        &:hover {
          color: var(--whiteColor);
          background-image: url("../images/menu-selected-bg-dark.svg");
          background-size: 100%;
        }
      }
    }
  }

  &.simple_image {
    li {

      a,
      .button {
        padding: 0 20px;
        height: 40px;
        @include flexAlign;
        font-size: 12px;

        &::before,
        &::after {
          display: none;
        }

        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
          margin-right: 10px;
        }

        &.active,
        &.NamedLink_active {
          color: var(--whiteColor);
          background-image: url("../images/menu-selected-bg-dark.svg");
          background-size: 100%;
        }
      }
    }
  }
}

.card {
  background-color: var(--darkGreyColor);
  padding: 20px;
  color: var(--whiteColor);
  position: relative;
  transition: 0.3s ease-in-out all;
  overflow: hidden;
  margin-bottom: 30px;

  @media screen and (max-width: $breakpoint_1024) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: $breakpoint_768) {
    padding: 15px;
  }

  @media screen and (max-width: $breakpoint_576) {
    margin-bottom: 15px;
  }

  .link {
    display: block;
  }

  .name {
    text-shadow: 0 0 2px var(--bgColorTwo);
    font: 14px/21px $fontFamilySemiBold;
    letter-spacing: 0.7px;
    display: block;
    margin-bottom: 8px;
    color: var(--whiteColor);

    @media screen and (max-width: $breakpoint_992) {
      font-size: 13px;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 12px;
    }
  }

  p {
    font-size: 11px;
    line-height: 1.55;
    letter-spacing: 0.55px;
    @include text-truncate(2);
    margin-bottom: 10px;

    @media screen and (max-width: $breakpoint_768) {
      margin: 0 0 8px;
    }
  }

  .by_wrap {
    max-width: 65%;
  }

  &.detail_page {
    margin-bottom: 20px;
    height: auto;

    @media screen and (max-width: $breakpoint_576) {
      margin-bottom: 15px;
    }

    @media screen and (max-width: $breakpoint_320) {
      padding: 10px;
    }
  }

  &.channel_card {
    @media screen and (max-width: $breakpoint_410) {
      padding-bottom: 65px;
    }
  }
}

.subscription_list {
  .card {
    &.channel_card {
      @media screen and (max-width: $breakpoint_410) {
        padding-bottom: 15px;
      }
    }
  }
}

.by_wrap {
  @include flexAlign;
  margin-bottom: 10px;

  .u_img {
    width: 25px;
    height: 25px;
    border: solid 2px $white;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 10px 0 0;

    img {
      @include imgCover;
    }
  }

  span {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: var(--whiteColor);
  }
}

.rating_wrap {
  @include position(absolute, auto, 14px, 13px, auto);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: var(--whiteColor);
  background-color: var(--bgColorTwo);
  padding: 4px 5px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;

  .star {
    padding-left: 18px;
    position: relative;

    &::before {
      content: "";
      @include position(absolute, 50%, auto, auto, 0);
      transform: translateY(-50%);
      width: 12.9px;
      height: 12px;
      background: url("../images/star_white.svg") center no-repeat;
      background-size: contain;
    }
  }

  &.watch {
    bottom: auto;
    top: 10px;
    font-size: 11px;
    line-height: 1.55;
    letter-spacing: 0.55px;

    @media screen and (max-width: $breakpoint_650) {
      top: 0;
      right: 0;
      font-size: 8px;
    }
  }
}

.view_all {
  font: 12px/18px $fontFamilySemiBold;
  letter-spacing: 0.6px;
  color: var(--avatusColor);

  @media screen and (max-width: $breakpoint_480) {
    font-size: 10px;
  }

  &:hover {
    color: var(--avatusColor);
  }

  &::after {
    background-color: var(--avatusColor);
  }
}

.expand_wrap {
  margin-top: 10px;
  text-align: center;

  button {
    @include flexCenter;
    flex-direction: column;
    font-size: 11px;
    margin: 0 auto;

    &.expand_btn {
      color: $white;

      img {
        transform: rotate(180deg);
      }
    }
  }
}

.black_tag {
  font: 12px/18px $fontFamilyMedium;
  letter-spacing: 0.6px;
  color: var(--greyColorTwo);
  background-color: var(--blackColor);
  padding: 6px 10px;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 15px;
}

.count {
  font-size: 16px;
  font-family: $fontFamilyMedium;
  letter-spacing: 0.8px;
  color: var(--countColor);
  display: inline-block;
  margin-top: 2px;

  @media screen and (max-width: $breakpoint_768) {
    font-size: 14px;
  }

  &.silver {
    color: var(--matterColorAnti);
  }

  &.gold {
    color: var(--countColor);
  }
}

.c_price {
  position: relative;
  padding-left: 22px;
  display: inline-block;
  font-size: 11px;
  line-height: 1.55;
  letter-spacing: 0.55px;
  color: var(--lightGreyColor);

  @media screen and (max-width: $breakpoint_768) {
    padding-left: 16px;
  }

  &::before {
    content: "";
    @include position(absolute, 50%, auto, auto, 0);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url("../images/avatcoin_gold.svg") center no-repeat;
    background-size: contain;

    @media screen and (max-width: $breakpoint_768) {
      width: 12px;
      height: 12px;
    }
  }

  &.silver {
    &::before {
      background: url("../images/avatcoin_silver.svg") center no-repeat;
      background-size: contain;
    }

    .count {
      color: var(--matterColorAnti);
    }
  }

  &.amount {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  &.mix {
    padding-left: 28px;

    &::before {
      width: 22px;
      height: 22px;
      background: url("../images/avatcoin_mix.svg") center no-repeat;
      background-size: contain;
    }
  }

  &.urgent_text {
    color: var(--avatusColor);
  }
}

.actions {
  @include position(absolute, 0, 0, auto, auto);
  display: flex;

  button {
    @include flexAlign;
    height: 20px;
    min-width: auto;
    padding: 0;

    @media screen and (max-width: $breakpoint_650) {
      height: 13px;
    }

    @media screen and (max-width: $breakpoint_320) {
      width: 12px;
      height: 12px;
    }
  }

  li {
    @include flexAlign;
    margin-left: 12px;

    .more {
      display: inline-block;
      width: 18px;
      height: 17px;
      background: url("../images/more-options.svg") center no-repeat;
      background-size: contain;

      @media screen and (max-width: $breakpoint_650) {
        width: 13px;
        height: 13px;
      }

      @media screen and (max-width: $breakpoint_320) {
        width: 12px;
        height: 12px;
      }
    }

    .reply {
      display: inline-block;
      width: 22px;
      height: 22px;
      background: url("../images/message_reply.svg") center no-repeat;
      background-size: contain;

      @media screen and (max-width: $breakpoint_650) {
        width: 13px;
        height: 13px;
      }

      @media screen and (max-width: $breakpoint_320) {
        width: 12px;
        height: 12px;
      }
    }

    .share {
      display: inline-block;
      cursor: pointer;
      width: 15px;
      height: 15px;
      background: url("../images/share.svg") center no-repeat;
      background-size: contain;

      @media screen and (max-width: $breakpoint_650) {
        width: 13px;
        height: 13px;
      }

      @media screen and (max-width: $breakpoint_320) {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.wishlist {
  >svg {
    fill: none;
    stroke: var(--whiteColor);
    stroke-width: 1px;
  }
}

.wishlisted {
  svg {
    fill: var(--avatusColor);
    stroke: var(--whiteColor);
  }
}

.tabs_wrap {
  padding: 14px 20px 2px 5px;
  @include flexAlign;
  width: 100%;
  background-color: var(--darkGreyColor);
  margin-bottom: 20px;

  @media screen and (max-width: $breakpoint_992) {
    overflow-x: auto;
  }

  @media screen and (max-width: $breakpoint_767) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: $breakpoint_576) {
    padding: 10px 5px 5px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: $breakpoint_480) {
    padding-top: 10px;
  }

  li {
    @media screen and (max-width: $breakpoint_576) {
      flex: 1;
    }

    a,
    button {
      color: var(--lightGreyColor);
      font-size: 14px;
      letter-spacing: 0.7px;
      line-height: 1.5;
      position: relative;
      text-align: center;
      display: block;
      width: 120px;
      padding-bottom: 12px;
      margin-right: 5px;

      @media screen and (max-width: $breakpoint_1366) {
        font-size: 13px;
      }

      @media screen and (max-width: $breakpoint_650) {
        width: 100%;
        margin-right: 30px;
      }

      @media screen and (max-width: $breakpoint_576) {
        font-size: 12px;
      }

      &:after {
        content: "";
        @include position(absolute, auto, auto, 0, 0);
        width: 0;
        height: 4px;
        border-radius: 0;
        background: var(--whiteColor);
        transition: all ease 0.4s;
        -webkit-transition: all ease 0.4s;
        -o-transition: all ease 0.4s;
      }

      &:hover,
      &.active {
        background: transparent;
        font-family: $fontFamilySemiBold;
        color: var(--whiteColor);

        &:after {
          width: 114px;

          @media screen and (max-width: $breakpoint_650) {
            width: 100%;
          }
        }
      }
    }
  }

  &.inner {
    background-color: transparent;
  }

  &.stick_top {
    @include position(sticky, 78px, auto, auto, auto);
    z-index: 98;
  }
}

.nav_tabs {
  @include flexAlign;
  margin-bottom: 20px;

  @media screen and (max-width: $breakpoint_768) {
    overflow-x: auto;
  }

  li {
    margin-right: 10px;
    text-align: center;

    a {
      font-size: 12px;
      line-height: 1.33;
      color: var(--lightGreyColor);
      background-color: var(--darkGreyColor);
      padding: 8px 15px;
      min-width: 100px;
      display: block;

      &.active {
        background-color: var(--blackColorFour);
        color: var(--whiteColor);
      }
    }
  }
}

.intro_video {
  video {
    width: 100%;
    min-width: 100%;
    min-height: auto;
    height: calc(100vh - 200px);
    object-fit: cover;
    cursor: pointer;
    z-index: 1;

    @media screen and (max-width: $breakpoint_1180) {
      height: calc(100vh - 190px);
    }

    @media screen and (max-width: $breakpoint_1024) {
      height: calc(100vh - 170px);
    }

    @media screen and (max-width: $breakpoint_820) {
      height: auto;
      min-height: calc(100vh - 396px);
    }

    @media screen and (max-width: $breakpoint_576) {
      min-height: calc(100vh - 350px);
      margin-top: -20px;
    }
  }

  .next_btn {
    @include position(absolute, 15px, 15px, auto, auto);
    max-width: 135px;
    z-index: 9;

    @media screen and (max-width: $breakpoint_1024) {
      right: 32px;
    }

    @media screen and (max-width: $breakpoint_576) {
      max-width: 100px;
    }
  }
}

.custom_controls {
  .video_controls {
    @include position(absolute, auto, auto, 0, 0);
    width: 100%;
    padding: 15px 20px 10px;
    z-index: 9;
    transition: all 0.2s ease;
    background: transparent linear-gradient(180deg, #08080800 0%, rgba(0, 0, 0, 0.7) 100%) 0% 0% no-repeat padding-box;

    @media screen and (max-width: $breakpoint_480) {
      padding: 10px;
    }

    ul {
      @include flexAlign;

      li {
        @include flexAlign;

        &:not(:last-child) {
          margin-right: 16.5px;

          @media screen and (max-width: $breakpoint_320) {
            margin-right: 10px;
          }
        }
      }
    }

    .icon {
      width: 22px;
      height: 18px;
      display: inline-block;
      cursor: pointer;

      &.play {
        background: url("../images/play.svg") center no-repeat;
        background-size: contain;
        transform: rotate(0deg);
        animation: rotate 0.5s forwards;
        -webkit-animation: rotate 0.5s forwards;

        @keyframes rotate {
          0% {
            transform: rotate(-90deg) scale(0.8);
          }

          100% {
            transform: rotate(0deg);
          }
        }
      }

      &.pause {
        background: url("../images/pause.svg") center no-repeat;
        background-size: contain;
      }

      &.forward {
        width: 24px;
        height: 24px;
        background: url("../images/secs_forward.svg") center no-repeat;
        background-size: contain;
      }

      &.backward {
        width: 24px;
        height: 24px;
        background: url("../images/secs_backward.svg") center no-repeat;
        background-size: contain;
      }

      &.mute {
        background: url("../images/mute.svg") center no-repeat;
        background-size: contain;
        width: 22px;
        height: 22px;
      }

      &.unmute {
        background: url("../images/unmute.svg") center no-repeat;
        background-size: contain;
        width: 22px;
        height: 22px;
      }

      &.fullscreen {
        background: url("../images/fullscreen.svg") center no-repeat;
        background-size: contain;
      }

      &.exit {
        background: url("../images/exit.svg") center no-repeat;
        background-size: contain;
      }

      &.cc_enable {
        background: url("../images/cc_enable.svg") center no-repeat;
        background-size: contain;
      }

      &.cc_disable {
        background: url("../images/cc_disable.svg") center no-repeat;
        background-size: contain;
      }
    }

    &.full_screen {
      .icon {
        width: 28px;
        height: 22px;
      }

      .progress_bar {
        width: 95%;
      }

      .duration {
        font-size: 12px;
        top: -10px;
      }
    }

    &.avatus-hide-player {
      opacity: 0;
      -webkit-transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
      transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
    }

    &.avatus-show-player {
      opacity: 1;
      -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
      transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    }

    .duration {
      font-size: 10px;
      line-height: 2.1;
      letter-spacing: 0.25px;
      @include position(absolute, -8px, 0, auto, auto);
      color: $white;
    }

    .volume_control {
      position: relative;

      .slider {
        top: -3px;
        height: 4px;
        width: 57px;
        background: var(--blackColorTwoBgThree);
        margin-right: 10px;
        position: relative;
        transition: all 0.6s ease;
        opacity: 0;
        visibility: hidden;
      }

      .done_progress {
        background-color: var(--borderColor);
      }

      .filled {
        background: var(--whiteColor);
        width: 100%;
        height: 100%;
        transition: width 0.2s ease-in-out;
      }

      &:hover {
        .slider {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .f_spacebw {
      margin-bottom: 5px;

      @media screen and (max-width: $breakpoint_480) {
        margin-bottom: -5px;
      }
    }
  }

  .volume_input {
    height: 4px;
    border: none;
    padding: 0;
    @include position(absolute, 0, auto, auto, 0);
    width: 100%;
    background-color: transparent;
    cursor: pointer;

    &::-webkit-slider-thumb {
      display: none;
    }
  }
}

.video-player {
  >div {
    @include flexCenter;
  }
}

.react_wrap {
  border-bottom: 0.5px solid var(--borderColor);
  padding: 0 0 15px;
  margin: 15px 0;

  @media screen and (max-width: $breakpoint_650) {
    padding: 0 0 10px;
    margin: 10px 0;
  }

  @media screen and (max-width: $breakpoint_576) {
    margin-bottom: 0;
  }

  .react {
    font-size: 12px;
    padding-left: 35px;
    position: relative;

    @media screen and (max-width: $breakpoint_650) {
      padding-left: 25px;
      font-size: 10px;
    }

    &::before {
      content: "";
      width: 22px;
      height: 20px;
      background: url("../images/feed-react.svg") center no-repeat;
      background-size: contain;
      @include position(absolute, 50%, auto, auto, 0);
      transform: translateY(-50%);
      margin-top: -1px;

      @media screen and (max-width: $breakpoint_650) {
        width: 16px;
        height: 16px;
        top: 50%;
      }
    }

    &.liked {
      &::before {
        background: url("../images/feed-liked.svg") center no-repeat;
        background-size: contain;
      }
    }
  }

  >ul {
    @include flexAlign;

    >li {
      width: 20px;
      height: 20px;

      @media screen and (max-width: $breakpoint_992) {
        width: 18px;
        height: 18px;
      }

      @media screen and (max-width: $breakpoint_650) {
        width: 16px;
        height: 16px;
      }

      &:not(:last-child) {
        margin-right: 20px;

        @media screen and (max-width: $breakpoint_650) {
          margin-right: 14px;
        }
      }

      &.save {
        cursor: pointer;
        background: url("../images/feed-save.svg") center no-repeat;
        background-size: contain;

        &.saved {
          background: url("../images/feed-saved.svg") center no-repeat;
        }
      }

      &.share {
        background: url("../images/feed-share.svg") center no-repeat;
        background-size: contain;
      }

      button {
        padding: 0;
        display: block;
        width: 100%;
        min-width: auto;
      }

      .more {
        width: 20px;
        height: 20px;
        background: url("../images/more-options.svg") center no-repeat;
        background-size: contain;
        display: block;

        @media screen and (max-width: $breakpoint_650) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.plus {
  display: inline-block;
  margin: 0 5px;

  @media screen and (max-width: $breakpoint_767) {
    font-size: 14px;
  }

  @media screen and (max-width: $breakpoint_480) {
    font-size: 12px;
  }
}

.post_desc_scroll {
  max-height: 350px;
  @include scroller("y");

  @media screen and (max-width: $breakpoint_576) {
    max-height: 200px;
  }
}

.post_desc {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.12px;
  margin: 0 0 10px;
  word-break: break-word;
  -ms-word-break: break-word;

  @media screen and (max-width: $breakpoint_767) {
    line-height: normal;
  }

  @media screen and (max-width: $breakpoint_576) {
    padding-right: 10px;
  }

  @media screen and (max-width: $breakpoint_360) {
    font-size: 10px;
  }

  span {
    display: inline-block;
    cursor: pointer;

    button {
      margin-left: 8px;
      transition: width 0.2s ease-in-out;
      font-family: $fontFamilySemiBold;

      &:hover {
        color: var(--lightGreyColor);
      }
    }
  }
}

.post_reactions {
  font-size: 12px;
  @include flexAlign;

  @media screen and (max-width: $breakpoint_360) {
    font-size: 10px;
  }

  .p_count {
    font-family: $fontFamilySemiBold;
  }

  >ul {
    @include flexAlign;
    margin-right: 6px;

    .post_item {
      figure {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        @media screen and (max-width: $breakpoint_650) {
          width: 12px;
          height: 12px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
}

.views_count {
  font-size: 12px;

  @media screen and (max-width: $breakpoint_650) {
    font-size: 10px;
  }
}

.progress_bar {
  position: relative;
  width: 100%;
  display: block;
  height: 4px;
  margin: 10px 0;
  background-color: var(--matterColor);

  &.slider {
    background: var(--blackColorTwoBgThree);

    &::-webkit-slider-runnable-track,
    &::-moz-slider-runnable-track {
      background: transparent;
    }
  }

  .done_progress {
    height: 4px;
    cursor: pointer;
    border: none;
    padding: 0;
    top: -13px;
    left: 0;
  }

  .seek-tooltip {
    display: none;
    @include position(absolute, -50px, auto, auto, auto);
    margin-left: -20px;
    font-size: 12px;
    padding: 3px;
    content: attr(data-title);
    font-family: $fontFamilyBold;
    color: var(--whiteColor);
    background-color: var(--bgColorTwo);
  }

  .seek {
    &:hover {
      &+.seek-tooltip {
        display: block;
      }
    }
  }

  input[type="range"] {
    margin: auto;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    overflow: hidden;
    outline: none;
  }

  progress {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0 0 20px;
    width: 100%;
    height: 4px;
    @include position(absolute, 0, auto, auto, auto);

    &::-webkit-progress-bar,
    &::-moz-progress-bar {
      background-color: var(--whiteColorOne);
    }

    &::-webkit-progress-value {
      background: var(--avatusColor);
    }

    &::-moz-progress-bar {
      background: var(--avatusColor);
      height: 2px;
    }

    &::-moz-progress-bar {
      background: #fff6;
    }
  }

  &::-webkit-slider-runnable-track {
    background: var(--matterColor);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  &::-moz-range-track {
    height: 40px;
    background: var(--matterColor);
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    background: transparent;
    height: 40px;
    width: 0;
    border-radius: 0;
    border: 0.1em solid var(--matterColor);
    box-shadow: -100vw 0 0 100vw var(--avatusColor);
    box-sizing: border-box;
  }

  &.post_video {
    @include position(absolute, auto, auto, 0, auto);
    margin: 0;
    background-color: transparent;
    height: 2px;

    .progress_bar {
      height: 2px;
      margin: 0;
      background-color: transparent;
      border: none;

      &::-webkit-progress-bar {
        background-color: transparent;
      }

      &::-moz-progress-bar {
        background-color: transparent;
      }
    }

    &::-webkit-slider-runnable-track {
      background: transparent;
    }
  }
}

.video_progress {
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  @media screen and (max-width: $breakpoint_480) {
    margin-bottom: 15px;
  }

  progress {
    &::-webkit-progress-value {
      background: var(--avatusColor);
    }
  }

  .done_progress {
    height: 4px;
    border: none;
    padding: 0;
    @include position(absolute, 0, auto, auto, 0);
    width: 85%;
    background-color: transparent;
    cursor: pointer;

    &.fullscreen {
      width: 95%;
    }

    &::-webkit-slider-thumb {
      width: 0;
      height: 0;
      background: transparent;
      background-size: contain;
      -webkit-appearance: none;
      margin: 0;
      cursor: pointer;
      border: none;
    }

    &::-moz-range-thumb {
      width: 0;
      height: 0;
      background: transparent;
      background-size: contain;
      appearance: none;
      -moz-appearance: none;
      margin: 0;
      cursor: pointer;
      border: none;
    }
  }

  .progress_bar {
    width: 85%;
    background: var(--whiteColorOne);
    margin: 0;
    @include position(absolute, 0, auto, auto, auto);
    border: none;

    &::-webkit-slider-runnable-track {
      background: var(--whiteColorOne);
    }
  }

  .fullscreen-bar-main {
    width: 95%;
  }

  &::-moz-progress-bar {
    background-color: var(--avatusColor);
  }

  &.settings {
    >div {
      width: calc(100% - 68px);
    }

    &.full_screen {
      >div {
        width: calc(100% - 68px);
      }
    }
  }
}

.post_viewer_left_col {
  .progress_bar {
    &.post_video {
      bottom: 0;
    }
  }

  .video_overlay {
    height: 100%;
  }
}

.bottom_wrap {
  @include position(absolute, auto, auto, 5px, 0);
  width: 100%;
  padding: 10px 20px 0;
}

.btn_wrapper {
  @include flexAlign;

  &.btn_wrapper_center {
    justify-content: center;
  }
}

.no-background {
  .switchLabel {
    color: var(--avatusColor);
  }
}

.loader_wrapper {
  @include flexCenter;
  height: 100%;
}

.star-ratings {
  display: flex !important;
  align-items: center;

  .star-container {
    width: 20%;
    text-align: center;
  }
}

.visually_hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.horizontal_events {
  ul {
    .horizontal_event_item {
      &:not(:nth-last-of-type(1)) {
        padding-right: 30px;

        @media screen and (max-width: $breakpoint_1024) {
          padding-right: 0;
        }
      }

      &:only-child {
        padding-right: 0;
      }
    }
  }
}

.toggle_switch {
  @include position(absolute, 0, 0, 0, auto);
  @include flexCenter;

  .switch_label {
    font: 12px/1.33 $fontFamilyMedium;
    text-transform: uppercase;
    color: var(--lightGreyColor);

    &.on {
      color: var(--avatusColor);
    }
  }

  .switch {
    align-items: center;
    margin-left: 4px;
  }
}

.info {
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  letter-spacing: 0.35px;
  color: var(--whiteColor);
  display: inline-block;
  margin-bottom: 32px;
}

.char_count {
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: var(--lightGreyColor);
  text-align: right;
  display: block;
  margin-bottom: 30px;

  &.mb-10 {
    margin-bottom: 10px;
  }
}

.events_card_wrapper {
  margin-bottom: 30px;

  @media screen and (max-width: $breakpoint_1024) {
    margin-bottom: 20px;
  }

  .card {
    margin-bottom: 0;
    height: 100%;
  }
}

.overflow_unset {
  overflow: unset !important;
}

.static_content_logo {
  text-align: center;
  margin: 20px auto 50px;

  img {
    width: 120px;

    @media screen and (max-width: $breakpoint_1024) {
      width: 100px;
    }

    @media screen and (max-width: $breakpoint_576) {
      width: 75px;
    }
  }
}

.static_content {
  h2 {
    font-size: 14px;
    margin: 0 0 10px 0;

    @media screen and (max-width: $breakpoint_1024) {
      font-size: 13px;
    }
  }

  ol,
  ul {
    li {
      font: 13px/20px $fontFamily;

      @media screen and (max-width: $breakpoint_576) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .list_head {
      font-size: 13px;
      text-decoration: underline;

      @media screen and (max-width: $breakpoint_576) {
        font-size: 12px;
      }
    }
  }

  strong {
    font-family: $fontFamilySemiBold;
  }

  .paraText {
    font: 13px/20px $fontFamily;
    margin-bottom: 16px;
    word-break: break-word;

    @media screen and (max-width: $breakpoint_576) {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 12px;
    }
  }

  .link {
    color: var(--avatusColor);
    font: 13px/20px $fontFamily;
    word-break: break-word;

    @media screen and (max-width: $breakpoint_950) {
      font-size: 13px;
    }

    @media screen and (max-width: $breakpoint_576) {
      font-size: 12px;
      line-height: 18px;
    }

    &::after {
      background-color: var(--avatusColor);
    }
  }

  .unordered_list {
    li {
      display: block;
      margin-bottom: 16px;
    }
  }

  .simple_list {
    li {
      margin-bottom: 0;
    }
  }

  .ordered_list {
    padding-left: 20px;

    li {
      list-style-type: lower-alpha;
      padding-left: 5px;
    }

    &.nested_level_1 {
      li {
        list-style-type: lower-roman;
      }
    }

    &.dotted_list {
      li {
        list-style-type: circle;

        .list_head {
          font-family: $fontFamilySemiBold;
          margin: 8px 0;
        }
      }
    }

    &.squared_list {
      li {
        list-style-type: square;
      }
    }

    &.upper_alpha_list {
      li {
        list-style-type: upper-alpha;
      }
    }

    &.numbered_list {
      li {
        list-style-type: decimal;

        .list_head {
          text-decoration: none;
        }
      }
    }

    &.lower_alpha_list {
      li {
        list-style-type: lower-alpha;
      }
    }

    &.disc_list {
      li {
        list-style-type: disc;
      }
    }
  }

  .link_list {
    p {
      margin-bottom: 0;
    }
  }

  &.mb-30 {
    margin-bottom: 30px;
  }
}

.profile_bg_dark {
  background-image: url("../images/avatus-bg-dark.png");

  @media screen and (max-width: $breakpoint_1024) {
    background-image: url("../images/avatus-bg-dark-mobile.png");
  }
}

.profile_bg_light {
  display: none;
}

.avatar_btn {
  background: url("../images/customizeImage.png") center no-repeat;
}

.backend_content {
  strong {
    font-family: $fontFamilySemiBold;
    font-size: 14px;
    margin: 0 0 10px;
    display: inline-block;
    line-height: 32px;
  }

  p {
    font: 13px/20px $fontFamily;
    margin-bottom: 16px;

    @media screen and (max-width: $breakpoint_576) {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 12px;
    }

    strong {
      margin: 0;
    }
  }

  ul {
    padding-left: 20px;

    li {
      padding-left: 5px;
      list-style-type: disc;
    }
  }

  ol {
    padding-left: 20px;

    li {
      padding-left: 5px;
      list-style-type: decimal;
    }
  }
}

.testtransparent,
.testtransparent * {
  background-color: transparent !important;
}

.testtransparent .center_div {
  @include flexCenter;
  width: 100%;
  height: 100vh;
}

.avatar_box_wrap {
  width: 100%;
  margin: 0 auto;
}

.avatar_box {
  height: 287px;
  width: 200px;
  position: relative;
  margin: 0px auto 25px;
  cursor: grab;

  span {
    color: var(--whiteColor);
  }
}

.identity_set {
  @media screen and (max-width: $breakpoint_1024) {
    padding-top: 20px;
  }

  .avatar_box {
    height: 425px;
    width: 280px;
    margin-bottom: 10px;
    z-index: 1;

    @media screen and (max-width: $breakpoint_767) {
      height: 405px;
    }

    @media screen and (max-width: $breakpoint_320) {
      width: 100%;
    }

    @media screen and (max-width: $breakpoint_280) {
      height: 375px;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 110px;
      background-image: url("../images/avatar_stand.png");
      z-index: -1;
      background-size: contain;

      @media screen and (max-width: $breakpoint_767) {
        width: 100%;
        bottom: -20px;
      }

      @media screen and (max-width: $breakpoint_320) {
        bottom: -30px;
      }
    }
  }
}

.avatar_selected_outfit {
  .avatus_img {
    width: 100%;
    position: absolute;
    top: 30%;
    z-index: 0;
    display: none;
  }

  .avatar_box {
    position: relative;
    bottom: 80px;
    left: -28px;
    z-index: 1;
    margin: 0;
    width: auto;
    height: 420px;

    @media screen and (max-width: $breakpoint_767) {
      height: 380px;
      bottom: 95px;
      margin-top: 70px;
    }

    @media screen and (max-width: $breakpoint_320) {
      bottom: 95px;
    }
  }
}

.my_profile_avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint_768) {
    margin-top: 50px;
  }

  .avatar_box_wrap {
    height: calc(100% - 60px);

    @media screen and (max-width: $breakpoint_767) {
      height: 412px;
      margin-bottom: 20px;
    }
  }

  .avatar_box {
    width: 345px;
    height: 100%;
    margin-bottom: 20px;
    @include flexCenter;
    overflow: hidden;
  }

  .btn-container {
    position: relative;
    top: -85px;

    @media screen and (max-width: $breakpoint_768) {
      top: -30px;
    }
  }
}

.user_avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .avatar_box_wrap {
    height: 412px;
    margin-bottom: 20px;
  }

  .avatar_box {
    width: 345px;
    height: 100%;
    margin-bottom: 20px;
    @include flexCenter;
    overflow: hidden;
  }

  .btn-container {
    position: relative;
    top: -85px;
  }
}

.avatarsteps_container {
  position: relative;
  width: 100%;
  @include flexCenter;

  @media screen and (max-width: $breakpoint_767) {
    padding: 15px 10px 100px;
  }
}

.poweredby_unionavatars {
  position: absolute;
  right: 0;
  bottom: 30px;

  @media screen and (max-width: $breakpoint_1600) {
    bottom: 25px;
  }

  @media screen and (max-width: $breakpoint_1366) {
    bottom: 20px;
    right: 20px;
  }

  @media screen and (max-width: $breakpoint_1024) {
    right: 35px;
  }

  @media screen and (max-width: $breakpoint_767) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  .powered {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    color: var(--whiteColor);

    @media screen and (max-width: $breakpoint_1600) {
      font-size: 13px;
    }

    @media screen and (max-width: $breakpoint_1280) {
      font-size: 12px;
    }

    @media screen and (max-width: $breakpoint_992) {
      font-size: 11px;
    }

    @media screen and (max-width: $breakpoint_767) {
      font-size: 10px;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 9px;
    }
  }

  img {
    max-width: 120px;

    @media screen and (max-width: $breakpoint_1600) {
      max-width: 100px;
    }

    @media screen and (max-width: $breakpoint_1280) {
      max-width: 90px;
    }

    @media screen and (max-width: $breakpoint_767) {
      max-width: 80px;
    }
  }

  &.mobile {
    display: none;
  }
}

.create_avatar_step_1 {
  position: relative;

  .avatarInfo {
    min-width: 36px;
    width: 36px;
    height: 36px;
    margin-left: 10px;

    &.desktop {
      position: absolute;
      right: 100px;
      top: 10px;
      z-index: 1;
      display: block;

      @media screen and (max-width: $breakpoint_1024) {
        top: 30px;
      }

      @media screen and (max-width: $breakpoint_767) {
        display: none;
      }
    }

    &.mobile {
      margin-left: 10px;
      display: none;

      @media screen and (max-width: $breakpoint_767) {
        display: block;
      }
    }
  }
}

.avatar_info_menu {
  .MuiPaper-root {
    .MuiList-root {
      max-height: unset !important;
      min-width: 360px;
      width: 100%;
      color: var(--whiteColor);
      padding: 20px !important;

      @media screen and (max-width: $breakpoint_480) {
        min-width: 250px !important;
        max-width: 100% !important;
      }

      h2 {
        margin: 0 0 10px 0;
        font-family: $fontFamily;
        font-weight: 400;
        letter-spacing: 0.1px;
        font-size: 16px;
        line-height: normal;

        @media screen and (max-width: $breakpoint_576) {
          font-size: 14px;
        }
      }

      .sm_title {
        display: block;
        font-size: 13px;
        line-height: 20px;
        color: var(--whiteColorOne);

        @media screen and (max-width: $breakpoint_576) {
          font-size: 12px;
        }
      }

      .requirement_list {
        padding-left: 14px;

        .list_item {
          list-style: decimal;
          margin-top: 12px;
          font-size: 14px;
          color: var(--whiteColor);

          @media screen and (max-width: $breakpoint_576) {
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.avatar_mobile_app {
  @media screen and (max-width: $breakpoint_767) {
    padding: 0;
  }

  .avatar_loader_wrap {
    height: calc(100vh - 100px);
  }
}

.avatar_for_mobile {
  position: relative;

  .create_avatar_step_1 {
    // height: calc(100vh - 220px);
    width: 100%;

    .edit_photo_btn {
      top: 50%;
    }
  }

  &:has(.create_avatar_step_2) {
    .avatarsteps_container {
      height: 100vh;
    }
  }
}

.avatar_view_for_app {
  .avatarsteps_container {
    min-height: 100vh;
  }

  .create_avatar_step_1 {
    position: relative;
    height: calc(100vh - 220px);

    .upload_btn {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @media screen and (max-width: $breakpoint_1024) {
        top: 56%;
        bottom: unset;
      }
    }
  }

  .create_avatar_step_2,
  .create_avatar_step_4 {
    min-height: calc(100vh - 220px);
  }

  &.step_6_layout {
    .avatarsteps_container {
      padding-top: 10px !important;
    }
  }
}

.avatar_outfit_step {
  min-height: 100%;
  background-image: url("../../assets/images/bg_avatus.png");
  background-size: cover;
  background-position-x: center;
  background-position-y: 40%;

  @media screen and (max-width: $breakpoint_1024) {
    background-image: url("../../assets/images/bg_avatus_mobile.png");
    background-position-y: 25%;
  }

  @media screen and (max-width: $breakpoint_767) {
    background-position-y: 35%;
  }

  .black_wrapper {
    width: 100%;
    background-color: transparent;

    .custom_container {
      @include flexCenter;
    }
  }

  &:has(.create_avatar_step_1),
  &:has(.create_avatar_step_2) {
    @include flexCenter;
  }

  &:has(.create_avatar_step_3) {
    @include flexCenter;

    .create_avatar_step_3 {
      margin-top: 104px;

      .loader {
        margin-bottom: 50px;
      }
    }
  }

  @media screen and (max-width: $breakpoint_1024) {
    &:has(.create_avatar_step_3) {
      @include flexCenter;

      .create_avatar_step_3 {
        margin-top: 100px;

        .loader {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.step_6_mobile_view {
  padding-left: 0;
  padding-right: 0;

  .create_avatar_step_6 {
    padding: 0 10px;

    .choose_outfit_row {
      margin-bottom: 0;
    }
  }

  .avatarsteps_container {
    @media screen and (max-width: $breakpoint_767) {
      padding-bottom: 160px;
      min-height: 100vh;
    }
  }

  .poweredby_unionavatars {
    @media screen and (max-width: $breakpoint_767) {
      bottom: 70px;
    }
  }

  .fixed_bottom_button {
    @media screen and (max-width: $breakpoint_767) {
      // @include position(fixed, auto, 0, 20px, 0);
      margin-top: 8px !important;
      bottom: 0;
      width: 100%;

      button {
        width: 100% !important;
        max-width: 100%;

        &.btn-effect:hover {

          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }
  }
}

.step_6_layout {
  .avatarsteps_container {
    padding: 0 0 50px 0 !important;
  }

  .poweredby_unionavatars {
    &.desktop {
      @media screen and (max-width: $breakpoint_767) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: $breakpoint_767) {
        display: block;
        position: static;
        transform: none;
        margin: 0 auto 5px;
        text-align: left;
      }
    }
  }
}

.avatar_modal_wrap {
  background-image: url("../images/avatus-bg-dark.png") !important;

  @media screen and (max-width: $breakpoint_1024) {
    background-image: url("../images/avatus-bg-dark-mobile.png") !important;
    background-position-y: 25%;
  }

  .avatar_box {
    height: 550px;
  }
}

.avatarsteps_container {
  .web_cam {
    .avatar_box_wrap {
      @media screen and (max-width: $breakpoint_767) {
        height: 100%;
        @include flexCenter;
      }
    }

    .avatar_box {
      @media screen and (max-width: $breakpoint_767) {
        height: 250px;
        max-height: 100%;
        margin: 0 auto;
      }
    }
  }
}

.outfit_wrapper {
  background-image: url("../../assets/images/bg_avatus.png");
  background-size: cover;
  background-position-x: center;
  background-position-y: 40%;

  @media screen and (max-width: $breakpoint_1024) {
    background-image: url("../../assets/images/bg_avatus_mobile.png");
    background-position-y: 25%;
  }

  @media screen and (max-width: $breakpoint_767) {
    background-position-y: 35%;
  }

  .black_wrapper {
    background-color: transparent;
  }
}