@import 'assets/styles/_variables.scss';

.theaContainer {
    height: 100%;
    width: 100%;
    border: none;

    @media screen and (max-width: $breakpoint_767) {
        height: calc(100% - 10px);
    }
}

.theaPage_footer {
    @media screen and (max-width: $breakpoint_767) {
        display: none;
    }
}

.mobileColumn {
    display: flex;
    height: 100%;
}