@import './mixins';

.full_width {
  width: 100%;
}

.p0 {
  padding: 0;
}

.m0 {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;

  @media screen and (max-width: $breakpoint_650) {
    margin-top: 20px;
  }
}

.ml-25 {
  margin-left: 25px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-sm {
  margin-top: 15px;
}

.fs12 {
  span {
    font-size: 12px;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.zindex_101 {
  z-index: 101;
}

//-----------Flexbox classes --------------//

.flex_row {
  @include flexbox;
  flex-wrap: wrap;
  margin: 0 -10px;

  &.m-15 {
    margin: 0 -15px;

    @media screen and (max-width: $breakpoint_1024) {
      margin: 0 -10px;
    }

    @media screen and (max-width: $breakpoint_576) {
      margin: 0 -7px;
    }
  }
}

.flex_1 {
  flex: 1;
}

.flex_shrink_0 {
  flex-shrink: 0;
}

.flex_col_sm_2 {
  width: 16.66666667%;
  padding: 0 10px;
}

.flex_col_sm_3 {
  width: 25%;
  padding: 0 10px;

  @media screen and (max-width: $breakpoint_992) {
    width: calc(100% / 3);
  }

  @media screen and (max-width: $breakpoint_767) {
    width: 50%;
  }

  @media screen and (max-width: $breakpoint_576) {
    width: 100%;
  }

  &.post_col {
    @media screen and (max-width: $breakpoint_1024) {
      width: 25%;
    }

    @media screen and (max-width: $breakpoint_650) {
      width: calc(100% / 3);
    }

    @media screen and (max-width: $breakpoint_480) {
      padding: 0 6px;
    }
  }
}

.flex_col_sm_4 {
  width: 33.3%;
  padding: 0 10px;

  @media only screen and (min-device-width: $breakpoint_375) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 50%;
  }

  @media screen and (max-width: $breakpoint_992) {
    width: 50%;
  }

  @media screen and (max-width: $breakpoint_767) {
    width: 100%;
  }

  &.p-15 {
    padding: 0 15px;

    @media screen and (max-width: $breakpoint_1024) {
      padding: 0 10px;
    }
  }

  &.wishlist_card {
    @media screen and (max-width: $breakpoint_992) {
      width: calc(100% / 3);
    }

    @media screen and (max-width: $breakpoint_890) {
      width: calc(100% / 2);
    }

    @media screen and (max-width: $breakpoint_767) {
      width: calc(100% / 3);
    }

    @media screen and (max-width: $breakpoint_650) {
      width: calc(100% / 2);
    }

    @media screen and (max-width: $breakpoint_576) {
      padding: 0 7px;
    }
  }

  &.post_col {
    width: calc(100% / 3);
    margin-bottom: 20px;
  }
}

.flex_col_sm_5 {
  width: 41.666667%;
  padding: 0 10px;

  @media only screen and (min-device-width: $breakpoint_375) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 75%;
  }

  @media screen and (max-width: $breakpoint_650) {
    width: 100%;
  }
}

.flex_col_sm_6 {
  width: 50%;
  padding: 0 10px;

  @media screen and (max-width: $breakpoint_768) {
    width: 100%;
  }
}

.flex_col_sm_7 {
  width: 58.333333%;
  padding: 0 10px;

  @media screen and (max-width: $breakpoint_650) {
    width: 100%;
  }
}

.flex_col_sm_8 {
  width: 66.666667%;
  padding: 0 10px;

  @media screen and (max-width: $breakpoint_992) {
    width: 50%;
  }

  @media screen and (max-width: $breakpoint_767) {
    width: 100%;
  }
}

.flex_col_sm_9 {
  width: 75%;
  padding: 0 10px;
}

.flex_col_sm_10 {
  width: 83.33333333%;
  padding: 0 10px;
}

.flex_col_sm_12 {
  width: 100%;
  padding: 0 10px;
}

.col_xs_4 {
  @media screen and (max-width: $breakpoint_650) {
    width: 50%;
  }
}

.col_xs_6 {
  @media screen and (max-width: $breakpoint_768) {
    width: 50%;
  }
}

.col_full {
  @media screen and (max-width: $breakpoint_992) {
    width: 100%;
  }
}

.col_7 {
  @media screen and (max-width: $breakpoint_767) {
    width: 70%;
  }
}

.col_3 {
  @media screen and (max-width: $breakpoint_767) {
    width: 30%;
  }
}

.w_auto {
  @media screen and (max-width: $breakpoint_767) {
    width: auto;
  }
}

.center_flex {
  align-items: center;

  @media only screen and (min-device-width: $breakpoint_375) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    align-items: flex-start;
  }
}

.f_row {
  @include flexbox;
}

.f_spacebw {
  @include fpos(center, space-between);
}

.f_center {
  @include flexCenter;
}

.sp_wrap {
  .flex_row {
    justify-content: space-between;
  }

  .col_7,
  .col_3 {
    vertical-align: middle;

    @media screen and (max-width: $breakpoint_576) {
      width: auto;
    }
  }
}

//-----------Material --------------//

.MuiTypography-root,
.MuiInput-root,
.MuiMenuItem-root,
.MuiFormLabel-root {
  font-family: $fontFamily !important;
}

.MuiCircularProgress-colorPrimary {
  color: var(--avatusColor) !important;
  margin: 20px;
}

.notification_loader {
  .MuiCircularProgress-colorPrimary {
    width: 20px !important;
    height: 20px !important;
    margin: 40px 0 55px;
  }
}

.MuiInputLabel-outlined {
  z-index: 0 !important;
  margin: 0;
  color: var(--whiteColor);
}

.MuiFormLabel-root {
  @media screen and (max-width: $breakpoint_950) {
    font-size: 13px !important;
  }

  &.Mui-focused {
    color: var(--whiteColor) !important;
    font: normal normal normal 14px/16px $fontFamily !important;

    @include placeholder() {
      color: var(--greyColorTwo);
      font-family: $fontFamily !important;
    }
  }
}

.MuiOutlinedInput-input {
  color: var(--whiteColor) !important;
  font: normal normal normal 14px/16px $fontFamily !important;

  @include placeholder() {
    font-family: $fontFamily !important;
  }

  @media screen and (max-width: $breakpoint_950) {
    font-size: 13px !important;
    line-height: normal;
  }

  &.Mui-disabled {
    color: var(--disabledColor) !important;
    cursor: not-allowed !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-image: repeating-linear-gradient(-120deg,
      var(--silverColorBgOne),
      var(--silverColorBgTwo),
      var(--silverColorBgFive)) 0;
}

.MuiOutlinedInput-root {
  &.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: $errorColor !important;
    }
  }

  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-image: repeating-linear-gradient(-290deg,
          var(--silverColorBgThree),
          var(--silverColorBgFour)) 0;
    }
  }
}

.onboard_form_field {
  .MuiOutlinedInput-input {
    @media screen and (max-width: $breakpoint_768) {
      font-size: 14px !important;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 12px !important;
    }
  }

  .MuiFormLabel-root {
    @media screen and (max-width: $breakpoint_768) {
      font-size: 14px !important;
    }

    @media screen and (max-width: $breakpoint_480) {
      font-size: 12px !important;
    }
  }
}

.phone_form_field {
  position: relative;

  .MuiFormLabel-root {
    margin-left: 70px;
  }
}

.pr_100 {
  .MuiOutlinedInput-input {
    padding-right: 100px !important;
  }
}

.channel_paid_fee_input {
  .MuiOutlinedInput-input {
    padding-left: 40px !important;
    padding-right: 100px !important;
  }

  .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled) {
    padding-left: 40px !important;
  }
}

.MuiFormHelperText-root,
.MuiAlert-root {
  font-family: $fontFamily !important;
  font-family: $fontFamilyMedium;
}

.MuiInputBase-root {
  .MuiSelect-icon {
    top: 50% !important;
    right: 15px;
    transform: translateY(-50%);
  }
}

.dateOfBirthInput {
  margin-bottom: 20px;

  input {
    padding: 10px 15px;
    height: 33.63px;
    border-radius: 3px;
    font: 14px/16px $fontFamily !important;
    letter-spacing: 0.1px;

    @media screen and (max-width: $breakpoint_950) {
      font-size: 13px !important;
      line-height: normal;
    }

    @media screen and (max-width: $breakpoint_768) {
      height: 36px;
    }

    @media screen and (max-width: $breakpoint_650) {
      font-size: 12px !important;
    }

    @media screen and (max-width: $breakpoint_480) {
      padding: 10px 12px;
    }

    @include placeholder() {
      color: var(--greyColorTwo);
      opacity: 1;
      font-size: 14px;
      letter-spacing: 0.14px;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-image-slice: 1 !important;
    border-width: 1px;
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-image-slice: 1 !important;
      border-width: 1px !important;
    }
  }

  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-image-slice: 1 !important;
      border-width: 1px;
      border-image: linear-gradient(to right,
          var(--redColorBgOne) 100%,
          var(--redColorBgThree) 50%,
          var(--redColorBgTwo) -1%);
    }
  }

  .MuiOutlinedInput-root {
    &.Mui-error {
      border-color: transparent !important;
    }
  }

  .MuiFormLabel-filled {
    color: var(--whiteColor);
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.14px;

    &.Mui-focused {
      color: var(--whiteColor);
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.14px;
    }
  }

  .MuiIconButton-root {
    color: var(--whiteColor) !important;
  }
}

.dome_input {
  border: 1px solid var(--matterColor);
  width: 100%;
  position: relative;
  padding: 15px 14px;
  font-size: 14px;
  text-align: left;
  color: var(--matterColorPlaceholder);
  margin-bottom: 20px;
  max-width: 100%;
  font-family: $fontFamily;
  border-width: 1.5px;
  border-image-slice: 1 !important;
  border-image: repeating-linear-gradient(-120deg,
      var(--silverColorBgOne),
      var(--silverColorBgTwo),
      var(--silverColorBgFive)) 0;

  &.selected_dome {
    color: var(--domeInputColor);
  }
}

.category_filter {
  .category_selectbox {
    .MuiSelect-select {
      height: 38px;
      box-sizing: border-box;
      padding: 8px 20px;
      background: var(--blackColorTwo);
      border-radius: 0;
      background-image: url('../images/dropdown.svg');
      background-position: 90% center;
      background-size: 12px;
      background-repeat: no-repeat;
      padding-right: 50px !important;
      min-width: 180px;
      max-width: 200px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px !important;
      font-size: 12px !important;

      .placeholder {
        font-size: 12px;
      }
    }

    .MuiSelect-icon {
      display: none;
    }
  }
}

.MuiPickersCalendarHeader-switchHeader {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.MuiSwitch-root {
  @media screen and (max-width: $breakpoint_767) {
    width: 50px !important;
  }
}

.MuiSwitch-switchBase {
  position: absolute !important;

  &.Mui-checked {
    color: var(--avatusColor);
  }
}

.MuiButton-textPrimary {
  color: var(--avatusColor);
}

.checkbox,
.MuiCheckbox-colorSecondary {
  color: transparent !important;
  padding: 0 12px 0 0 !important;
  background: url('../../assets/images/checkbox.svg') center no-repeat;

  &.Mui-checked {
    background: url('../../assets/images/checkbox_filled.svg') center no-repeat;
    color: transparent !important;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.select_post_checkbox {
  position: absolute !important;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.filled_checkbox {
  background: url('../../assets/images/checkbox_white.svg') center no-repeat;

  &.Mui-checked {
    background: url('../../assets/images/checkbox_filled_white.svg') center no-repeat;
  }
}

.simple_checkbox {
  svg {
    display: none !important;
  }

  &.Mui-checked {
    background: url('../../assets/images/ic_condition_fulfilled.svg') center no-repeat;
  }
}

.events_none {
  pointer-events: none;
  user-select: none;
}

.label_absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#card-more-menu {
  .MuiPaper-root {
    margin: 0 0 0 12px;
    box-shadow: 0 0 20px 0 var(--bgColorThree);
  }

  .MuiMenuItem-root {
    label {
      font: 12px/16px $fontFamily !important;
      @include flexAlign;
    }

    img {
      margin-right: 12px;
    }
  }
}

.no-background {
  .switchLabel {
    color: var(--avatusColor);
  }
}

.MuiMenu-list {
  max-height: 250px !important;
  overflow-y: auto !important;
  max-width: 250px;
  min-width: 250px;
  overflow-x: hidden;
  padding: 6px 6px 2px !important;

  @media screen and (max-width: $breakpoint_767) {
    max-width: 200px;
    min-width: 200px;
  }

  &[aria-labelledby='header-secondary-menu-button'] {
    max-height: 350px !important;
  }
}

.MuiMenuItem-root {
  font: normal normal normal 12px/16px $fontFamily !important;
  letter-spacing: 0.12px !important;
  color: var(--whiteColor) !important;
  margin: 0 0 10px !important;
  padding: 0 20px !important;
  overflow: initial !important;
  min-height: 38px !important;
  transition: none !important;
  display: flex !important;
  justify-content: flex-start !important;

  @media screen and (max-width: $breakpoint_767) {
    min-height: 32px !important;
  }

  a {
    color: var(--whiteColor) !important;
    @include flexAlign;
    width: 100%;
    min-height: 38px;
    background-color: transparent;
    padding: 0 1px;

    @media screen and (max-width: $breakpoint_767) {
      min-height: 32px;
    }

    @media screen and (max-width: $breakpoint_576) {
      font-size: 11px;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  button {
    width: 100%;
    min-height: auto !important;
    text-align: left;
    font-family: $fontFamily;
  }

  .MuiTouchRipple-root,
  .MuiTouchRipple-rippleVisible {
    animation: none;
    opacity: 0;
    transition: none;
  }

  &:last-child {
    margin: 0 0 4px !important;
  }

  &.d_block_650 {
    display: none !important;

    @media screen and (max-width: $breakpoint_650) {
      display: flex !important;
    }
  }

  &:hover {
    &:not(.no-background) {
      // background-color: var(--avatusColor);
      background: url('../images/date-marker-red.svg') center no-repeat;
      background-size: cover;

      // &::before {
      //     content: '';
      //     @include position(absolute, 0, auto, auto, -20px);
      //     border-right: 20px solid var(--avatusColor);
      //     border-top: 18px solid transparent;
      //     border-bottom: 20px solid transparent;

      //     @media screen and (max-width: $breakpoint_767) {
      //         border-top-width: 15px;
      //         border-bottom-width: 17px;
      //     }
      // }

      // &::after {
      //     content: '';
      //     @include position(absolute, 0, -20px, auto, auto);
      //     border-left: 20px solid var(--avatusColor);
      //     border-top: 18px solid transparent;
      //     border-bottom: 20px solid transparent;

      //     @media screen and (max-width: $breakpoint_767) {
      //         border-top-width: 15px;
      //         border-bottom-width: 17px;
      //     }
      // }
    }
  }

  &.Mui-disabled {
    display: none;
  }

  &.Mui-selected {
    // background-color: var(--avatusColor) !important;
    // position: relative;
    background: url('../images/date-marker-red.svg') center no-repeat;
    background-size: cover;

    // &::before {
    //     content: '';
    //     @include position(absolute, 0, auto, auto, -20px);
    //     border-right: 20px solid var(--avatusColor);
    //     border-top: 18px solid transparent;
    //     border-bottom: 20px solid transparent;
    // }

    // &::after {
    //     content: '';
    //     @include position(absolute, 0, -19.5px, auto, auto);
    //     border-left: 20px solid var(--avatusColor);
    //     border-top: 18px solid transparent;
    //     border-bottom: 20px solid transparent;
    // }
  }

  &.with_toggle {
    justify-content: space-between !important;
  }

  &.Mui-focusVisible {
    background-color: transparent !important;

    &::before {
      border-right: 20px solid transparent;
    }

    &::after {
      border-left: 20px solid transparent;
    }

    &:hover {
      background-color: var(--avatusColor) !important;
    }
  }

  &.category_menu_disabled {
    opacity: 1 !important;
    border-bottom: 1px solid var(--borderColor);
    margin: 0 0 5px 0 !important;
    padding: 0px 15px !important;
    position: sticky;
    top: -6px;
    z-index: 1;
    background: var(--darkGreyColor);

    .heading {
      opacity: 0.8;
    }
  }

  &.category_menuitem {
    margin: 0 15px !important;

    .MuiTypography-root {
      font-size: 11px;
      color: var(--menuItemColor);
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }

    .option_txt {
      margin-right: 10px;
    }

    &.Mui-selected {
      background-color: transparent !important;

      .MuiTypography-root {
        color: var(--whiteColor);
        font-family: $fontFamilySemiBold !important;
      }

      &::before,
      &::after {
        display: none !important;
      }
    }

    &:hover {
      background-color: transparent !important;

      .MuiTypography-root {
        color: var(--whiteColor);
      }

      &::before,
      &::after {
        display: none !important;
      }
    }
  }

  .auto_renewal_switch {
    margin-right: -20px;

    .switch_label_on {
      color: var(--avatusColor);
    }
  }
}

#menu-datepicker_toolbar_month,
#menu-datepicker_toolbar_year {
  .MuiPaper-root {
    border: none;
    box-shadow: 0 0 20px 0 var(--bgColorThree) !important;
    background-color: var(--blackColorThree) !important;

    &::after {
      content: '';
      @include position(absolute, auto, 0, auto, auto);
      background-color: var(--whiteColor);
    }
  }

  .MuiMenu-list {
    min-width: 88px;
    max-width: 88px;
    max-height: 217px !important;

    .MuiMenuItem-root {
      @include flexCenter;
    }
  }
}

.MuiPaper-root {
  background-color: var(--darkGreyColor) !important;
  box-shadow: 0 0 20px 0 var(--bgColorThree) !important;
  border: solid 1px var(--greyColorThree);
  margin-top: 3px;
  border-radius: 0 !important;

  &.DOB {
    max-width: 88px !important;
    width: 100%;
  }

  &.MuiAlert-root {
    margin: 0;
    padding: 9px 25px 9px 50px !important;
    background: transparent linear-gradient(264deg,
        var(--whiteColorTwo) 0%,
        var(--whiteColorFour) 49%,
        var(--whiteColorTwo) 100%) 0% 0% no-repeat padding-box;
    clip-path: polygon(6% 0, 94% 0, 100% 50%, 94% 100%, 6% 100%, 0% 50%);
    border: none;
    z-index: 1;
    max-width: 400px;

    .MuiAlert-icon {
      padding: 0;
      @include position(absolute, 50%, auto, auto, 20px);
      transform: translateY(-50%);
      margin: 0;
    }

    .MuiAlert-message {
      padding: 0;
    }

    &.toast_msg {
      &::before {
        clip-path: polygon(6% 0, 94% 0, 100% 50%, 94% 100%, 6% 100%, 0% 50%);
        display: block;
        content: '';
        padding: 2px;
        @include position(absolute, 1px, 1px, 1px, 1px);
        background-color: var(--blackColorTwo);
        z-index: -1;
      }

      .MuiAlert-icon {
        width: 20px;
        height: 20px;
        background-image: url('../images/info-icon-white.svg');

        svg {
          display: none !important;
        }
      }
    }
  }
}

.arrow_over {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid var(--darkGreyColor);
    position: absolute;
    top: -25px;
    z-index: 9999;
  }
}

.simple_menu_container {
  .MuiPaper-root {
    box-shadow: 0px 7px 20px #000000db !important;
    border: none !important;
    max-width: 220px !important;
    margin-top: -25px;
  }

  .MuiMenu-list {
    padding-bottom: 6px !important;
    max-width: 100% !important;
    min-width: 220px !important;
    overflow-x: hidden;
    max-height: 200px !important;
  }

  .MuiMenuItem-root {
    margin: 0 !important;
    padding: 0 16px !important;
    font-size: 11px !important;

    .saved_menu_img {
      width: 26px;
      height: 26px;
      object-fit: cover;
      margin-right: 10px;
    }

    &:hover {
      background-color: rgba($black, 0.2) !important;

      &::before,
      &::after {
        display: none !important;
      }
    }

    &.no_bg {
      .add_collection {
        cursor: default;
        @include fpos(center, space-between);
        width: 100%;

        >span {
          font-family: $fontFamilySemiBold;
          letter-spacing: 0.12px;
          color: var(--whiteColor);
          font-size: 12px;
        }

        button {
          width: auto;
        }
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.MuiInput-underline {

  &.Mui-focused::after,
  &.Mui-focused::before {
    display: none;
  }
}

.MuiFormControlLabel-labelPlacementEnd {
  @include fpos(flex-start, space-between);
  flex-direction: row-reverse;
  margin: 0 2px 7px 0 !important;

  .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.35px;
    color: var(--lightGreyColor);
    margin-right: 10px;

    @media screen and (max-width: $breakpoint_480) {
      font-size: 12px;
    }
  }

  .MuiRadio-root {
    padding: 0 10px 0 0;
    background: url('../images/ic-radio-inactive.svg') center no-repeat;
    width: 25px;
    height: 25px;
    color: transparent;
    background-size: contain;

    &.Mui-checked {
      background: url('../images/ic-radio-active.svg') center no-repeat;
      color: transparent;

      ~span {
        color: var(--whiteColor);
        font-family: $fontFamilyMedium;
      }
    }
  }
}

.checkbox_wrap {
  margin-left: -8px;

  .MuiFormControlLabel-labelPlacementEnd {
    justify-content: flex-start;
    flex-direction: row;

    .MuiFormControlLabel-label {
      color: var(--whiteColor);
      font-family: $fontFamilyMedium;
    }
  }

  .checkbox,
  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      background: url('../../assets/images/red_tick.svg') center no-repeat;
      color: transparent !important;
    }
  }
}

.MuiFormControl-root {
  width: 100%;
}

.MuiDialog-container {
  .MuiDialogActions-root {
    display: none;
  }

  .MuiPickersToolbar-penIconButton {
    color: var(--whiteColor);
    margin-left: 10px;
    display: none;

    svg {
      width: 20px;
    }
  }

  .MuiPickersToolbar-root {
    padding: 10px 18px;

    .MuiTypography-overline {
      display: none;
    }

    .MuiTypography-root {
      color: var(--whiteColor);
    }

    .MuiTimePickerToolbar-hourMinuteLabel {
      .MuiTimePickerToolbar-separator {
        opacity: 0;
        margin: 0;
      }

      .MuiButton-text {
        min-width: 95px;
        justify-content: flex-start;
        background: var(--blackColorThree);
        padding: 5px 12px;
        font-size: 26px;
        height: 48px;
        border-radius: none;

        .MuiTypography-root {
          font-size: 26px;
        }
      }
    }

    .MuiTimePickerToolbar-ampmSelection {
      margin-right: inherit;

      .MuiTimePickerToolbar-ampmLabel {
        font-size: 13px;
        border-bottom: 1px solid transparent;
        color: var(--whiteColorOne);
        line-height: 16px;
        margin-bottom: 5px;

        &.Mui-selected {
          color: var(--whiteColor);
          border-bottom-color: var(--avatusColor);
        }
      }
    }
  }

  .MuiClockPicker-root {
    margin-bottom: 10px;

    .css-1umqo6f,
    .css-12ha4i7 {
      background-color: var(--textColor);
    }

    .css-1flhz3h {
      color: var(--whiteColor);
      font-family: $fontFamily;
    }

    .css-7lip4c,
    .css-a1rc6s {
      background-color: var(--avatusColor);

      .css-118whkv,
      .css-118whkv {
        border-color: var(--avatusColor);
        background-color: var(--avatusColor);
      }
    }

    .css-2ujp1m {
      border-color: var(--avatusColor);
    }
  }

  .css-1ngni0z {
    padding: 12px 8px 5px;

    label {
      color: var(--whiteColor);
    }
  }
}

.MuiPickersBasePicker-container {
  box-shadow: 0 0 20px var(--bgColorThree);
  border: 1px solid var(--greyColorThree);
  background-color: var(--darkGreyColor);
  flex-direction: column !important;

  .MuiPickersClock-squareMask {
    cursor: pointer;
  }

  .MuiSelect-select {
    &.MuiSelect-select {
      padding: 10px 24px 10px 2px;
      font-size: 14px;
      font-family: $fontFamilyMedium;
      line-height: 1.14;
      letter-spacing: 0.14px;
      color: var(--whiteColor);
      @include flexCenter;
    }
  }

  .MuiInputBase-root {
    .MuiSelect-icon {
      right: 10px;
    }
  }
}

.MuiPickersBasePicker-pickerView {
  min-height: auto !important;
  margin-top: 10px;

  .MuiPickersCalendar-transitionContainer {
    min-height: 200px;
  }
}

.MuiPickersDay-day {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.14px;
  color: var(--whiteColor) !important;

  &.MuiPickersDay-daySelected {
    background: url('../images/date-marker-red.svg') center no-repeat !important;
  }

  &:hover {
    background: url('../images/date-marker-red.svg') center no-repeat !important;
  }

  &:hover,
  &:focus {
    span.MuiTouchRipple-root {
      animation: none;
      opacity: 0;
      transition: none;
    }
  }
}

.MuiPickersDay-dayDisabled {
  color: var(--whiteColorThree) !important;
}

.MuiPickersCalendarHeader-dayLabel,
.MuiTypography-caption {
  font-size: 10px !important;
  letter-spacing: 0.1px !important;
  color: var(--greyColorTwo) !important;
  text-transform: uppercase;
}

.MuiTooltip-tooltip {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  font-family: $fontFamilyMedium !important;
}

.MuiSlider-rail {
  background-color: var(--matterColor) !important;
  opacity: 1 !important;
}

.MuiSlider-root {
  color: var(--avatusColor) !important;
  margin: 0 10px 20px !important;
  max-width: calc(100% - 30px) !important;

  &+span {
    &.MuiSlider-root {
      margin: 0 10px !important;
    }
  }

  .MuiSlider-thumb {
    width: 20px !important;
    height: 20px !important;
    background: url('../images/slider_toggle_black.svg') center no-repeat;
    background-size: contain;

    &:hover {
      box-shadow: none !important;
    }
  }
}

.radio_form {
  margin-bottom: 5px;
  padding: 15px 8px 0 0;
  max-height: 300px;

  fieldset {
    width: 100%;
  }

  .top_line {
    border-top: solid 1px var(--borderColor);
    padding-top: 20px;
  }

  &.wishlist {
    .MuiFormControlLabel-labelPlacementEnd {
      margin: 0 !important;
      padding: 0;

      .MuiFormControlLabel-label {
        color: var(--whiteColor);
      }
    }
  }
}

.radiogroup_styling {
  .MuiRadio-root {
    padding: 0 15px 0 0;
    background: url('../images/ic-radio-inactive.svg') center no-repeat;
    width: 25px;
    height: 25px;
    color: transparent;
    background-size: contain;

    &.Mui-checked {
      background: url('../images/ic-radio-active.svg') center no-repeat;
      color: transparent;

      ~span {
        color: var(--whiteColor);
        font-family: $fontFamilyMedium;
      }
    }
  }
}

.settings_account {
  .radio_form {
    max-height: $breakpoint_360;
  }

  .f_center {
    justify-content: flex-start;
    border: none;
  }

  .MuiFormControlLabel-labelPlacementEnd {
    margin: 0 0 12px 0;

    .MuiFormControlLabel-label {
      color: var(--whiteColor);
    }
  }
}

//------------------ Cropper --------------------//

.img_cropper {
  margin-bottom: 20px;
  background-color: transparent;

  .ReactCrop--fixed-aspect {
    display: block;
    height: 100%;

    >div {
      &:first-child {
        height: 100%;
      }
    }

    .ReactCrop__image {
      margin: auto;
      @include imgContain;
      max-height: calc(100vh - 270px);

      @media screen and (min-height: $breakpoint_912) {
        max-height: 500px;
      }
    }
  }

  .img_cropped {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.avatar_img_cropper {
    .ReactCrop--fixed-aspect {
      .ReactCrop__image {
        object-fit: cover;
      }
    }
  }
}

.imageModal.container_fix,
.postModal.container_fix {
  >div {
    >.zoom_container {
      background-color: transparent;
      max-width: 1170px;
      clip-path: none;
      box-shadow: none;

      @media screen and (max-width: $breakpoint_1366) {
        max-width: 95%;
      }

      @media screen and (max-width: $breakpoint_1180) {
        max-width: 100%;
      }

      &::before,
      &::after {
        display: none;
      }

      >figure {
        height: 600px;
        background-color: var(--blackColor);

        @media screen and (max-width: $breakpoint_1366) {
          height: 400px;
        }

        @media screen and (max-width: $breakpoint_768) {
          height: $breakpoint_650;
        }

        @media screen and (max-width: $breakpoint_650) {
          height: 350px;
        }

        img {
          @include imgContain;
        }
      }

      button {
        &.cross {
          right: 0;
          padding: 0;
          border: 0.2px solid var(--whiteColorThree);
          width: 35px;
          height: 35px;
          @include flexCenter;
          padding: 11px;

          @media screen and (max-width: $breakpoint_650) {
            right: 10px;
            top: -10px;
          }

          @media screen and (max-width: $breakpoint_576) {
            width: 30px;
            height: 30px;
            padding: 9px;
          }
        }
      }
    }
  }

  .postviewer__collection {
    background-color: var(--darkGreyColor);
    max-width: 400px;
    clip-path: unset;
    box-shadow: 0 0 20px var(--bgColorThree);

    &::before,
    &::after {
      display: unset;
    }
  }
}

.postModal.container_fix {
  @media screen and (max-width: $breakpoint_1366) {
    max-width: 100%;
  }

  .zoom_container {
    .post_modal_inner {
      max-width: 990px;
      margin: 0 auto;

      @media screen and (max-width: $breakpoint_1180) {
        max-width: 100%;
      }
    }

    button {
      &.cross {
        right: 0;

        @media screen and (max-width: $breakpoint_650) {
          top: 20px;
        }
      }
    }

    .custom_controls {
      min-height: 100%;
      max-height: 100%;
      margin: 0;
      overflow: hidden;

      @media screen and (max-width: $breakpoint_576) {
        min-height: 220px;
        max-height: 220px;
      }

      .video_controls {
        bottom: 0;
      }
    }
  }

  .feed_slider {
    height: 100%;

    .card_slider {
      height: 100%;
      padding-bottom: 0;

      .react-multi-carousel-list {
        height: 100%;

        @media screen and (max-width: $breakpoint_576) {
          width: 100%;
        }
      }

      .react-multi-carousel-dot-list {
        bottom: 20px;
      }

      .react-multi-carousel-track {
        height: 100%;

        .react-multi-carousel-item {
          height: 100%;

          figure {
            height: 100%;

            @media screen and (max-width: $breakpoint_576) {
              height: 300px;
            }

            @media screen and (max-width: $breakpoint_375) {
              height: 250px;
            }

            @media screen and (max-width: $breakpoint_320) {
              height: 220px;
            }

            img {
              @include imgContain;
              border: 1px solid var(--blackColorFour);
              background-color: var(--blackColor) !important;
            }
          }
        }
      }

      .react-multiple-carousel__arrow {
        bottom: 40px;
        transform: initial;
        top: auto;

        &.react-multiple-carousel__arrow--right {
          right: 40px;
        }

        &.react-multiple-carousel__arrow--left {
          left: 40px;
        }
      }
    }
  }
}

.magnifierModal.container_fix {
  backdrop-filter: blur(7px);

  .zoom_container {
    @include flexCenter;
    height: 100%;
    max-width: 100%;
    padding: 0 100px;

    >div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    figure {
      background-color: transparent;
      height: calc(100% - 20px);

      img {
        height: 100%;
      }
    }

    .cross {
      margin-top: 20px;
      right: 100px !important;
    }
  }
}

.exitAvatusModal.container_fix {
  .zoom_container {
    background-color: var(--darkGreyColor);
    @include flexCenter;
    height: 100%;
    max-width: 100%;
    padding: 0 100px;

    &::after,
    &::before {
      display: none;
    }

    .cross {
      display: none;
    }

    .exit_avatus_modal {
      @include flexbox;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .warning_img {
        margin-bottom: 14px;
      }

      .heading {
        font: 18px/22px $fontFamilySemiBold;
        letter-spacing: 0.45px;
        color: var(--whiteColor);
        margin: 0 0 6px;
      }

      .para {
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.4px;
        color: var(--paraColor);
        max-width: 286px;
        margin: 0 auto 30px;
        text-align: center;
      }

      button {
        max-width: 275px;
        height: 50px;
        font-size: 16px;
      }

      .btn_yes {
        margin-top: 10px;
      }

      .btn_back {
        color: var(--lightGreyColor);
        margin-top: 15px;
        border-color: var(--darkGreyColor);

        &:hover {
          border-color: var(--textColor);
        }
      }
    }
  }
}

.theaModal.container_fix {
  .zoom_container {
    background-color: transparent;
    clip-path: none;
    box-shadow: none;
    max-width: 100%;
    height: 100vh;
    padding: 0;

    &::before,
    &::after {
      display: none;
    }

    @media screen and (max-width: $breakpoint_650) {
      background-size: 100%;
    }

    .thea_gif_modal {
      @include position(absolute, 0, 0, 0, 0);
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media screen and (max-width: $breakpoint_1024) {
        display: none;
      }
    }

    .thea_gif_modal_mobile {
      @include position(absolute, 0, 0, 0, 0);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: none;

      @media screen and (max-width: $breakpoint_1024) {
        display: block;
      }
    }
  }
}

.image-crop.container_fix {
  padding: 0 20px;

  .zoom_container {
    max-width: 520px;

    &::before {
      top: -24px;
      height: 24px;
    }

    &::after {
      bottom: -24px;
      height: 24px;
    }
  }
}

.tutorials_modal.container_fix {
  .zoom_container {
    max-width: 100%;
    background: rgba($black, 0.6);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    @include flexCenter;
    clip-path: none;

    &::after,
    &::before {
      display: none;
    }
  }

  .tutorial_modal_wrap {
    @include flexAlign;
    justify-content: center;
    flex-direction: column;
    padding: 60px 20px;

    @media screen and (max-width: $breakpoint_1366) {
      padding: 20px;
    }

    .tutorial_img_wrap {
      height: calc(100vh - 240px);
      max-height: 520px;
      min-height: 100%;

      @media screen and (max-width: $breakpoint_1366) {
        height: calc(100vh - 150px);
      }

      .tutorial_image {
        max-width: 100%;
        height: 100%;
        max-height: calc(100vh - 240px);
        animation-delay: 4s;
      }
    }

    .dot_list {
      @include flexCenter;
      margin-top: 24px;

      @media screen and (max-width: $breakpoint_1366) {
        margin-top: 20px;
      }

      .dot_item {
        margin: 0 4px;

        >button {
          width: 14px;
          height: 14px;
          background-color: var(--blackColorSix);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }

        &.active {
          >button {
            background-color: var(--avatusColor);
          }
        }
      }
    }

    .tutorial_action_btn {
      margin-top: 30px;

      @media screen and (max-width: $breakpoint_1366) {
        margin-top: 20px;
      }

      .btn_tutorial {
        min-width: 135px;
      }
    }

    &.fade {
      .tutorial_image {
        animation-name: fade;
        animation-duration: 0.4s;
        animation-timing-function: ease-in;
      }
    }
  }
}

.edit_avatar_modal_container {
  .zoom_container {
    max-width: 456px !important;

    @media screen and (max-width: 480px) {
      max-width: 90% !important;
    }
  
    .cross {
      display: none;
      top: 38px;
    }
  }

  .edit_avatar_modal {
    padding-top: 12px;
    padding-bottom: 10px;

    .title {
      margin-bottom: 30px;
      padding-bottom: 0;
      border-bottom: 0;
      font-size: 22px;

      @media screen and (max-width: $breakpoint_1366) {
        font-size: 20px;
      }

      @media screen and (max-width: $breakpoint_767) {
        font-size: 18px;
      }
    }

    .buttons_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-avatar_change {
        font-size: 14px;
        height: 50px;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 20px;
        }

        &:hover {
          background-color: var(--avatusColor);
        }
      }
    }
  }
}

.isOpen {
  .zoom_container {
    &:has(#payment-widget-container.widgetCont.show) {
      max-width: unset !important;
    }

    .widgetCont {
      >div {
        iframe {
          height: auto !important;
          min-height: 450px;
        }
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//------------------ Carousel --------------------//

.card_slider {
  position: relative;

  .react-multiple-carousel__arrow {
    @include position(absolute, 50%, 0, auto, auto);
    min-width: 30px;
    min-height: 30px;
    margin-top: -10px;
    border-radius: 0;
    transform: translateY(-50%);
    z-index: 99;

    &:before {
      display: none;
    }

    &.react-multiple-carousel__arrow--left {
      left: 0;
      right: auto;
      background: url('../images/ic-prev.png') no-repeat scroll 0 0;
      background-size: contain;
    }

    &.react-multiple-carousel__arrow--right {
      background: url('../images/ic-next.png') no-repeat scroll 0 0;
      background-size: contain;
      opacity: 1;
    }
  }

  .react-multi-carousel-item {
    >div {
      &:first-of-type {
        height: 100%;
      }
    }
  }

  .card {
    width: 100%;
    margin-bottom: 0;
    height: 100%;
  }
}

.feed_slider {
  .card_slider {
    padding-bottom: 25px;

    .react-multi-carousel-dot-list {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      right: auto;
    }

    .react-multiple-carousel__arrow {
      min-width: 24px;
      min-height: 24px;
      right: 20px;

      &.react-multiple-carousel__arrow--left {
        left: 20px;
        background: url('../images/feed-ic-prev.svg') no-repeat scroll 0 0;
      }

      &.react-multiple-carousel__arrow--right {
        background: url('../images/feed-ic-next.svg') no-repeat scroll 0 0;
      }
    }
  }
}

.feed_box_wrap {
  .card_slider {
    padding-bottom: 40px;

    .react-multi-carousel-dot-list {
      z-index: 11;
    }
  }

  .reactions_wrapper {
    bottom: 40px;
  }

  &+.feed_details {
    margin-top: -40px;
  }
}

.postviewer_reactions {
  position: relative;

  .reactions_wrapper {
    bottom: 0;
    flex-wrap: wrap;
    background-color: #0a090c;
    margin: 0 -20px;
    width: auto;
    border-bottom: 1px solid var(--borderColor);

    >figure {
      width: calc(100% / 4);
    }
  }
}

.react_zindex {
  z-index: 999 !important;
}

.leaderboard_slider {
  .card_slider {
    padding-bottom: 25px;

    .react-multi-carousel-item {
      >div:first-of-type {
        height: auto;
      }
    }

    .react-multi-carousel-dot-list {
      bottom: 0;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }

    .react-multiple-carousel__arrow {
      min-width: 58px;
      min-height: 58px;
      right: 20px;

      &.react-multiple-carousel__arrow--left {
        background: url('.././../assets/images/carousal-left-icon.png') no-repeat scroll 0 0;
        left: 20px;
        right: auto;
      }

      &.react-multiple-carousel__arrow--right {
        background: url('.././../assets/images/carousal-right.png') no-repeat scroll 0 0;
      }
    }
  }
}

.channel_slider {
  .card_slider {
    .card {
      width: 415px;

      @media screen and (max-width: $breakpoint_768) {
        width: 275px;
      }

      @media screen and (max-width: $breakpoint_650) {
        width: 95%;
      }
    }
  }

  .react-multi-carousel-track {
    @media screen and (max-width: $breakpoint_650) {
      transform: initial;
    }
  }
}

.explore_slider {
  margin: 0 -15px;

  @media screen and (max-width: $breakpoint_1024) {
    margin: 0 -10px;
  }

  @media screen and (max-width: $breakpoint_576) {
    margin: 0 -7px;
  }

  .react-multi-carousel-track {
    .react-multi-carousel-item {
      &.explore_slide_item {
        padding: 0 15px;

        @media screen and (max-width: $breakpoint_1024) {
          padding: 0 10px;
        }

        @media screen and (max-width: $breakpoint_576) {
          padding: 0 7px;
        }
      }
    }
  }

  &.w_100 {
    .react-multi-carousel-track {
      width: 100%;
    }
  }
}

.react-multi-carousel-item[aria-hidden='true'] {

  button,
  a {
    visibility: hidden;
  }
}

.react-multi-carousel-dot-list {
  li {
    button {
      width: 6px;
      height: 6px;
      border: solid 0.5px var(--doveGrayColor);
      border-radius: 0;
      margin-right: 4px;
    }

    &.react-multi-carousel-dot--active {
      button {
        background: var(--whiteColor);
        border-color: var(--whiteColor);
      }
    }
  }
}

.mui_carousel {
  overflow: initial !important;

  @media screen and (max-width: $breakpoint_1180) {
    overflow-x: hidden !important;
  }

  button[aria-label='Previous'],
  button[aria-label='Next'] {
    margin: 0;
    padding: 0;
    background-color: transparent;
    pointer-events: all;

    @media screen and (max-width: $breakpoint_576) {
      top: 40% !important;
    }

    &:hover {
      background-color: transparent;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  button[aria-label='Previous'] {
    @media screen and (min-width: $breakpoint_1366) {
      left: -55px;
      right: auto;
      transform: translateX(-50%);
    }
  }

  button[aria-label='Next'] {
    @media screen and (min-width: $breakpoint_1366) {
      right: -55px;
      left: auto;
      transform: translateX(50%);
    }
  }

  >div {

    &:nth-last-child(2),
    &:last-child {
      pointer-events: none;
    }
  }
}

body {
  &.fullscreen {
    footer {
      position: static;
    }

    .mui_carousel {
      &>div {
        position: static;
      }
    }
  }
}

.accordion {
  .MuiAccordionSummary-content {
    margin: 20px 0;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint_576) {
      margin: 16px 0;
    }

    &.Mui-expanded {
      @media screen and (max-width: $breakpoint_576) {
        margin: 16px 0;
      }
    }
  }

  .summary {
    padding: 0 20px;
    min-height: auto;

    .heading {
      color: var(--whiteColor);
      font-size: 12px;
      line-height: 1.14;
      letter-spacing: 0.35px;

      @media screen and (max-width: $breakpoint_1366) {
        font-size: 13px;
      }

      @media screen and (max-width: $breakpoint_576) {
        font-size: 12px;
      }
    }
  }

  .accordion_details {
    padding: 0 20px;

    .paraDesc {
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: 0.06px;
      color: var(--lightGreyColor);
      margin-bottom: 18px;
      word-break: break-word;
    }
  }

  .expand_icon {
    margin: 0 6px;
  }

  &.Mui-expanded {
    .expand_icon {
      transform: rotate(180deg);
    }
  }

  &.updated_accordion {
    .MuiAccordionSummary-root {
      &.Mui-expanded {
        min-height: auto;
      }
    }

    .MuiAccordionSummary-content {
      &.Mui-expanded {
        margin: 20px 0 10px;
      }
    }

    .accordion_details {
      .paraDesc {
        padding-left: 7px;
        border-left: 3px solid var(--avatusColor);
      }
    }
  }
}

.identity_wrapper {
  max-width: 370px;
  margin: 0 auto;

  h1 {
    margin-bottom: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .MuiOutlinedInput-multiline {
    textarea {
      min-height: 155px;
    }
  }

  .fill_red_btn {
    max-width: 100%;
    height: 40px;
  }
}

.request_dome_form {
  .MuiOutlinedInput-multiline {
    textarea {
      min-height: 125px;
    }
  }
}

//-----------Modals --------------//

.send_modal {
  .inner_title {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: var(--whiteColor);
    display: block;
    margin-bottom: 15px;
  }

  .send_wrap {
    @include flexAlign;
    padding: 0 40px 15px 40px;
    margin-bottom: 20px;
    border-bottom: solid 0.5px var(--borderColor);

    .thumb {
      width: 80px;
      height: 80px;
      margin: 0 15px 0 0;
      flex-shrink: 0;

      @media screen and (max-width: $breakpoint_360) {
        width: 60px;
        height: 60px;
      }

      @media screen and (max-width: $breakpoint_320) {
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
      }

      img {
        @include imgCover;
      }
    }

    span {
      max-width: 70%;
      @include text-truncate(2);
      word-break: break-all;

      @media screen and (max-width: $breakpoint_360) {
        font-size: 14px;
        max-width: 60%;
      }

      @media screen and (max-width: $breakpoint_320) {
        font-size: 12px;
      }
    }

    &.justify_content {
      justify-content: center;

      .thumb {
        margin: 0;
      }
    }
  }

  .recent_chats {
    padding-right: 4px;

    >div {
      @include flexbox;
      margin-right: 10px;
    }

    .img_wrapper {
      width: 30px;
      height: 30px;
      margin: 0;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      margin-right: 10px;

      img {
        @include imgCover;
      }
    }

    .name {
      font-size: 12px;
      font-family: $fontFamilyMedium;
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: var(--whiteColor);
      vertical-align: top;
      margin-top: 7px;
      display: inline-block;
      width: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:not(:nth-last-of-type(1)) {
      margin-bottom: 15px;
    }
  }

  .send_btn {
    background-color: var(--avatusColor);
    color: $white;
    font: 12px/16px $fontFamilyMedium;
    letter-spacing: 1.2px;
    min-height: auto;
    padding: 8.5px 15px;
    min-width: 69px;

    &.btn_done {
      background-color: transparent;
      border: 1px solid var(--avatusColor);
      color: var(--avatusColor);
      margin-right: 0;
    }
  }
}

.social_share_modal {
  .social_share {
    @include flexCenter;
    margin-bottom: 36px;

    li {
      display: inline-block;
      margin: 0 12px 0 0;
      text-align: center;
      transform: scale(var(--scale, 0.9));
      transition: transform 0.3s cubic-bezier(0.38, -0.12, 0.24, 1.91);

      &:last-child {
        margin: 0;
      }

      &:hover {
        --scale: 1;
      }

      figure {
        width: 60px;
        height: 60px;
        margin: 0;

        @media screen and (max-width: $breakpoint_576) {
          width: 50px;
          height: 50px;
        }

        @media screen and (max-width: $breakpoint_360) {
          width: 40px;
          height: 40px;
        }

        @media screen and (max-width: $breakpoint_320) {
          width: 30px;
          height: 30px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.3px;
        color: var(--whiteColor);

        @media screen and (max-width: $breakpoint_576) {
          display: none;
        }
      }
    }
  }

  .share_url {
    background-color: var(--blackColorThree);
    padding: 11px 75px 11px 10px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.12px;
    color: var(--whiteColor);
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .copy {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    min-height: auto;
    color: var(--avatusColor);
    font-family: $fontFamilySemiBold;
    font-size: 12px;

    &::after {
      background-color: var(--avatusColor);
    }
  }
}

.buy_modal {
  .item_img {
    width: 80px;
    height: 80px;
    background-color: var(--blackColorFour);
    @include flexCenter;
    margin: 0 16px 0 0;

    img {
      @include imgCover;
    }

    &.collection {
      img {
        width: 58px;
        height: 60px;
        object-fit: contain;
      }
    }
  }

  .item_detail {
    @include fpos(stretch, space-between);
    flex-flow: column;
    width: 72%;

    .name {
      font: 16px/20px $fontFamilyMedium;
      letter-spacing: 0.4px;
    }
  }

  .color_selector {
    @include flexAlign;

    li {
      margin: 0 8px 0 0;
      border-radius: 50%;
      padding: 1.5px;
      border: solid 0.4px var(--matterColor);

      a,
      button {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: block;

        &.white {
          background-color: var(--whiteColor);
        }

        &.brown {
          background-color: var(--brownColor);
        }

        &.red {
          background-color: var(--avatusColor);
        }
      }

      &.active {
        border-color: var(--whiteColor);
      }
    }
  }

  .wallet_info {
    padding: 15px 0 20px;
    border-top: 0.5px solid var(--borderColor);

    p {
      margin-bottom: 0;
    }

    .c_price {
      padding-left: 20px;

      &::before {
        width: 14px;
        height: 14px;
      }

      .count {
        font-size: 14px;
        color: var(--whiteColor);

        @media screen and (max-width: $breakpoint_576) {
          font-size: 12px;
        }

        @media screen and (max-width: $breakpoint_320) {
          font-size: 10px;
        }
      }
    }
  }

  .line {
    border-top: solid 1px var(--borderColor);
    padding-top: 20px;
    margin: 20px 0 0;

    span {
      font-size: 14px;
    }
  }
}

.create_wishlist_modal {
  .back {
    margin: 0;
    padding: 0;

    &::before {
      left: -75px;

      @media screen and (max-width: $breakpoint_650) {
        left: -60px;
      }

      @media screen and (max-width: $breakpoint_320) {
        left: -46px;
        width: 25px;
        height: 25px;
      }
    }

    &+span {
      margin-left: -10px;
    }
  }

  .btn_create {
    max-width: 115px;
    margin-top: 7px;
  }

  .MuiFormControlLabel-labelPlacementEnd {
    margin-bottom: 10px !important;
  }
}

.transaction_filter_modal {
  .form_label {
    margin-bottom: 15px;
  }

  .char_count {
    padding-top: 5px;
  }

  button {
    height: 40px;
  }
}

.new_collection_modal {
  .button_wrapper {
    margin-top: 45px;
  }
}

.payment_modal {
  .avatcoins {
    &::before {
      margin-top: -1px;
    }

    span {
      font-size: 15px;
    }
  }

  button,
  .fill_red_btn {
    max-width: 163px;
  }

  &.paymentFailed {
    @include flexAlign;
    flex-direction: column;
    padding-bottom: 0;

    p {
      text-align: center;
    }
  }
}

.subscribe_confirmation_modal {
  .p_title {
    margin: 0 0 10px;
    padding: 0;
  }

  .wallet_info {
    padding: 15px 0 20px;
    border-top: 0.5px solid var(--borderColor);

    p {
      margin-bottom: 0;
    }

    .c_price {
      padding-left: 20px;

      &::before {
        width: 14px;
        height: 14px;
      }

      .count {
        font-size: 14px;
      }
    }
  }

  .c_price {
    &.m_price {
      padding-left: 30px;

      &::before {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 20px;
      }
    }
  }
}

.report_user_modal {
  .report_title {
    margin-bottom: 7px;
    font: normal 14px $fontFamilyMedium;
  }

  .report_info {
    border-bottom: solid 0.5px var(--borderColor);
    margin-bottom: 10px;
    padding-bottom: 16px;
  }

  .modal_title {
    font-size: 14px;
    margin-bottom: 15px;
    display: block;
    color: var(--lightGreyColor);
  }

  .mb-30 {
    margin-bottom: 20px;
  }
}

.request_submitted_modal {
  .m_title {
    font: 20px/1.88 $fontFamilyMedium;
    text-align: center;
  }

  .info {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    margin: 15px 0;
    display: block;

    @media (max-width: $breakpoint_768) {
      font-size: 11px;
    }

    .p_label {
      color: var(--lightGreyColor);
    }
  }

  .line {
    border-top: solid 0.5px var(--borderColor);
    padding-top: 20px;
    margin: 16px 0 20px;

    span {
      font-size: 14px;
    }
  }
}

.terms_modal {
  .tnc {
    max-height: 370px;
    overflow-x: hidden;
    padding-right: 8px;
    margin-bottom: 20px;

    .txt_info {
      max-width: 340px;

      @media screen and (max-width: $breakpoint_650) {
        max-width: 100%;
        padding: 0 10px;
      }

      p {
        color: var(--whiteColor);
        font-size: 14px;
        margin: 0 0 15px 0;
        line-height: 1.29;
        letter-spacing: 0.35px;

        @media screen and (max-width: $breakpoint_650) {
          font-size: 13px;
        }

        @media screen and (max-width: $breakpoint_480) {
          font-size: 12px;
        }
      }
    }
  }

  .line {
    border-top: solid 0.5px var(--borderColor);
    padding-top: 20px;
  }

  .para_light {
    color: $lightGreyColor;
    text-align: left;
    margin-bottom: 15px;
  }

  .points {
    margin-bottom: 25px;

    li {
      text-align: left;
      font-size: 14px;
      line-height: 22px;
      padding-left: 12px;

      @media screen and (max-width: $breakpoint_767) {
        font-size: 13px;
      }

      @media screen and (max-width: $breakpoint_480) {
        font-size: 12px;
      }

      ::before {
        content: '-';
        color: var(--whiteColor);
        display: block;
        position: absolute;
        left: 19px;
      }
    }
  }
}

.reactions_modal {
  .m_title {
    font: 18px/32px $fontFamilyMedium;
    letter-spacing: 0.45px;
  }
}

.reaction_tabs {
  .tabs_box {
    padding: 0 3px;
    // min-width: 50px;
    border-bottom: 4px solid transparent;
    // @include flexbox;
    // @include custom-scroller('x');
    // max-width: 100%;

    .react_tab {
      border-bottom: 4px solid transparent;
      padding: 0 10px;
      min-width: 50px;
      @include flexAlign;
      flex-shrink: 0;
      padding-bottom: 15px;

      img {
        width: 36px;
      }

      .reaction_count {
        font-size: 11px;
        color: var(--whiteColor);
        font-family: $fontFamilyMedium;
        margin-left: 5px;
      }

      &.selected {
        border-bottom-color: var(--avatusColor);
      }
    }
  }
}

.chat_media_modal.container_fix {
  background-color: var(--blackColor) !important;

  .zoom_container {
    padding: 3px 100px 9px;
    background-color: transparent !important;
    max-width: 100% !important;
    height: 100% !important;

    &::before,
    &::after {
      display: none !important;
    }

    >div {
      height: 100%;
    }

    .chat_media_inner {
      height: 100%;
      @include flexCenter;

      .media_detail_wrap {
        position: relative;
        height: 100%;
        width: 100%;
        @include fpos(stretch, space-between);
        flex-direction: column;

        .user_detail_wrap {
          width: 100%;
          margin: 20px 0 0;
          text-align: center;

          h1 {
            font-size: 16px;
            line-height: 25px;
            font-family: $fontFamilyMedium;
            letter-spacing: 0.8px;
            color: var(--whiteColor);
            margin: 0;
          }

          .date {
            font: normal normal 12px/20px $fontFamily;
            letter-spacing: 0.12px;
            color: var(--lightGreyColor);
          }
        }
      }
    }

    .media_content {
      @include flexCenter;
      margin: 30px 0;
      height: calc(100vh - 140px);
      background-color: var(--blackColor);

      img,
      video {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      video {
        min-width: auto;
        min-height: 100%;
        object-fit: contain;
      }
    }

    button.cross {
      @include flexCenter;
      width: 35px;
      height: 35px;
      padding: 0;
      top: 20px;
      right: 100px !important;
      border: 0.2px solid var(--borderColor);
      padding: 11px;
    }
  }

  .mui_carousel {
    >div {
      right: 0 !important;
      left: auto;
    }

    >div:last-child {
      left: 0;
      right: auto !important;
    }
  }
}

.select_payment_options_modal {
  .m_title {
    border-bottom: none;
    margin-bottom: 0;
  }

  .txt_info {
    margin-top: 18px;

    @media screen and (max-width: $breakpoint_576) {
      margin-top: 8px;
    }

    p {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .price_radio_group {
    .MuiFormGroup-root {
      flex-direction: row;
      justify-content: center;

      .MuiFormControlLabel-labelPlacementEnd {
        .MuiFormControlLabel-label {
          border-bottom: 2px solid transparent;
          padding-bottom: 4px;
        }

        .MuiRadio-root {
          opacity: 0;
          position: absolute;

          &.Mui-checked {
            &+.MuiFormControlLabel-label {
              border-bottom-color: var(--whiteColor);
            }
          }

          &:not(.Mui-checked) {
            &+.MuiFormControlLabel-label {
              opacity: 0.4;
            }
          }
        }

        &:first-of-type {
          margin-right: 38px !important;
        }

        &:only-child {
          margin-right: 0 !important;
        }
      }
    }

    .plus {
      margin: 0 4px;
    }
  }
}

.static_page {
  h2 {
    font-size: 14px;
    margin: 0 0 10px 0;
  }

  .para {
    font: 13px/24px $fontFamily;
    margin: 0 0 10px 0;

    @media screen and (max-width: $breakpoint_1366) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  dl {
    dt {
      font: 13px/24px $fontFamilySemiBold;
      margin: 0 0 10px 0;

      @media screen and (max-width: $breakpoint_1366) {
        font-size: 12px;
        line-height: 22px;
      }
    }

    dd {
      margin-left: 0;
      margin-bottom: 30px;

      .para {
        margin-bottom: 6px;
      }
    }
  }

  .link {
    color: var(--avatusColor);
    font: 13px/24px $fontFamilySemiBold;
    text-decoration: underline;

    &::after {
      display: none;
    }

    &:hover {
      color: var(--avatusColor);
    }
  }

  .unordered_list {
    padding-left: 40px;

    li {
      list-style: disc;
      font: 13px/24px $fontFamily;
      margin: 0 0 10px 0;

      @media screen and (max-width: $breakpoint_1366) {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}

#header-notification-menu {
  .MuiPaper-root {
    max-width: 330px !important;
    width: 100%;

    @media screen and (max-width: $breakpoint_480) {
      left: 0 !important;
      right: 0;
      max-width: 95% !important;
      margin: 0 auto;
    }

    .MuiMenu-list {
      max-width: 100% !important;
      padding: 0 !important;
      max-height: 490px !important;

      @media screen and (max-width: $breakpoint_576) {
        max-height: 400px !important;
      }
    }

    .MuiMenuItem-root {
      margin: 0 !important;
      padding: 12px 20px !important;

      @media screen and (max-width: $breakpoint_480) {
        padding: 10px 15px !important;
      }

      button {
        text-align: center;
      }

      &:hover {
        &:not(.no-background) {
          background-color: var(--blackColorTwo) !important;
          color: var(--whiteColor) !important;
          background-image: none !important;

          &::before,
          &::after {
            display: none;
          }
        }
      }

      &.unread {
        background-color: var(--blackColorTwo);
      }
    }

    .notification_heading {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--darkGreyColor);

      h1 {
        font: 16px/25px $fontFamilySemiBold;
        letter-spacing: 0.8px;
        color: var(--whiteColor);
        margin: 6px 0 -5px;

        @media screen and (max-width: $breakpoint_576) {
          font-size: 14px;
          line-height: 20px;
          margin: 4px 0;
        }
      }
    }

    .notification_wrap {
      @include flexbox;
      width: 100%;

      figure {
        flex-shrink: 0;
      }

      .user_img {
        width: 30px;
        height: 30px;
        margin-right: 10px;

        img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .notification_content {
        flex: 1;

        p {
          margin: 0;
          line-height: 18px;
          word-break: break-word;
          white-space: normal;
          font-size: 11px;

          span {
            font-family: $fontFamilyMedium;
          }
        }
        .notification_link {
          color: $avatusColor !important;
          text-decoration: underline;
          word-break: break-all !important;
          white-space: normal !important;

        }

        .title {
          font-family: $fontFamilySemiBold;
          font-size: 13px;
          margin-bottom: 4px;
          letter-spacing: 0.2px;
        }

        .time {
          margin-top: 7px;
          display: block;
          font: 10px/16px $fontFamily;
          color: var(--lightGreyColor);
          margin-bottom: 12px;

          @media screen and (max-width: $breakpoint_576) {
            font-size: 9px;
            line-height: 14px;
          }
        }

        .btn {
          min-height: 33px !important;
          max-width: 95px;
          font-size: 12px;

          &.outline_btn {
            margin-left: 10px;
          }
        }
      }

      .post_img {
        margin-left: 20px;
        width: 40px;
        height: 40px;

        img {
          @include imgCover;
        }
      }
    }
  }
}

#saved-posts-select-menu {
  .MuiPaper-root {
    max-width: 200px !important;
    width: 100%;

    .MuiMenu-list {
      max-width: 100% !important;
      min-width: 200px !important;
    }

    .MuiMenuItem-root {
      min-height: 100% !important;
      height: 32px !important;

      &:hover {
        &:not(.no-background) {

          &::before,
          &::after {
            border-top-width: 16px;
            border-bottom-width: 16px;
          }
        }
      }
    }
  }
}

#menu-nationality,
#menu-gender {
  .MuiPaper-root {
    .MuiMenu-list {
      max-width: 100%;
    }
  }
}

.reactions-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index: 99999;
}


.tutorials_list_wrap {
  @include flexbox;
  flex-wrap: wrap;
  margin: 0 -10px;
  row-gap: 20px;

  @media screen and (max-width: $breakpoint_480) {
    margin-bottom: 15px !important;
    padding: 10px !important;
  }

  &>li {
    width: calc(100%/3);
    padding: 0 10px;

    @media screen and (max-width: $breakpoint_950) {
      width: 50%;
    }

    @media screen and (max-width: $breakpoint_480) {
      width: 100%;
    }
    .card {
      background-color: transparent;
      padding: 0;
      border: 1px solid var(--borderColor);
      overflow: hidden;
      border-radius: 5px;
      margin: 0;
      min-height: 100%;

      figure {
        height: 150px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }

      .name{
        padding: 10px;
        margin: 0;
      }
    }
  }

  &:hover {
    background-color: var(--darkGreyColor);
  }
}

.mobile_tutorials {
  width: 100%;
  padding: 20px 15px;

  .tutorials_list_wrap{
    padding: 0 !important;
    margin: 15px 0 0 !important;

    li{
      padding: 0 !important;
    }
  }
}