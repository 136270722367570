@import './mixins.scss';

.light_theme {
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba($color: $black, $alpha: .2) !important;
        -webkit-box-shadow: inset 0 0 6px rgba($color: $black, $alpha: .2) !important;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: #E8E8E8 !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #E8E8E8 !important;
    }

    .MuiPaper-root {

        &.MuiAlert-root {
            background: transparent linear-gradient(264deg, var(--blackColorTwoBgThree) 0%, var(--blackColorTwoBgFour) 49%, var(--blackColorTwoBgThree) 100%) 0% 0% no-repeat padding-box;

            .MuiAlert-icon {
                svg {
                    fill: var(--whiteColorOne);
                }
            }

            .MuiAlert-message {
                color: $black;
            }

            &.toast_msg {
                &::before {
                    background-color: $white;
                }
            }
        }
    }

    .MuiSwitch-switchBase {
        .MuiSwitch-thumb {
            &::before {
                background-image: url('../images/off-white.svg');
            }
        }
    }

    .MuiMenuItem-root {
        .dark_hover_img {
            display: none;
        }

        .light_hover_img {
            display: initial;
        }

        &:hover {
            color: $white !important;

            a {
                color: $white !important;
            }

            .dark_hover_img {
                display: initial;
            }

            .light_hover_img {
                display: none;
            }

            &.no-background {
                background-color: transparent;
                color: $black !important;

                a {
                    color: $black !important;
                }
            }
        }

        &.Mui-selected {
            color: $white !important;
        }
    }

    .MuiSlider-rail {
        background-color: var(--matterColor) !important;
        opacity: 1 !important;
    }

    .MuiSlider-root {

        .MuiSlider-thumb {
            background: url('../images/slider_toggle_white.svg') center no-repeat;
            background-size: contain;
        }
    }

    .MuiPickersCalendarHeader-dayLabel,
    .MuiTypography-caption {
        color: $black !important;
    }

    .MuiPickersDay-day {
        &.MuiPickersDay-daySelected {
            background: url('../images/date-marker-red.svg') center no-repeat !important;
            color: $white !important;
        }

        &.MuiPickersDay-dayDisabled {
            color: var(--timePickerColor) !important;
        }

        &:hover {
            background: url('../images/date-marker-red.svg') center no-repeat !important;
            color: $white !important;
        }
    }

    div[aria-labelledby="countryCode"] {
        color: $black;
    }

    .MuiPickersBasePicker-container {
        .MuiSelect-select {
            &.MuiSelect-select {
                background-color: $white;
            }
        }
    }

    .MuiDialog-container {

        .MuiPickersToolbar-root {

            .MuiTypography-root {
                color: var(--whiteColor);
            }
        }

        .MuiClockPicker-root {

            .css-1umqo6f,
            .css-12ha4i7 {
                background-color: var(--blackColorTwo);
            }

            .css-1flhz3h {
                &.Mui-selected {
                    color: $white;
                }
            }
        }
    }

    .MuiSelect-select {
        &:focus {
            background-color: transparent;
        }
    }

    .MuiFormControlLabel-labelPlacementEnd {

        .MuiRadio-root {

            &.Mui-checked {
                background: url('../images/ic_radio_active_black.svg') center no-repeat;
            }
        }
    }

    .checkbox,
    .MuiCheckbox-colorSecondary {
        &.Mui-checked {
            background: url('../../assets/images/checkbox_black.svg') center no-repeat;
        }
    }

    .category_filter {
        .category_selectbox {
            .MuiSelect-select {
                background-image: url('../images/dropdown-black.svg');
            }
        }
    }

    .simple_checkbox {
        &.Mui-checked {
            background: url('../../assets/images/ic_condition_fulfilled-black.svg') center no-repeat;
        }
    }


    button,
    a {
        &.fill_red_btn {
            &.disable_btn {
                background-color: var(--altoColor);

                &.btn-effect {
                    &:hover {
                        &:before {
                            border-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .3) 102%, rgba(0, 0, 0, 0) 0);
                            border-image-slice: 1 !important;
                        }

                        &:after {
                            border-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .3) 102%, rgba(0, 0, 0, 0) 0);
                            border-image-slice: 1 !important;
                        }
                    }
                }
            }
        }

        &.outline_btn {
            color: $black;
            border-color: rgba($black, .2);
        }
    }

    .fill_red_btn {
        &.btn-effect {
            &:hover {
                &:before {
                    border-image: linear-gradient(0deg, rgba(219, 7, 22, 0) 50%, rgba(219, 7, 22, .45) 102%, rgba(219, 7, 22, 0) 0);
                    border-image-slice: 1 !important;
                }

                &:after {
                    border-image: linear-gradient(180deg, rgba(219, 7, 22, 0) 0, rgba(219, 7, 22, .45) 102%, rgba(219, 7, 22, 0) 0);
                    border-image-slice: 1 !important;
                }
            }
        }
    }

    .outline_btn {
        &.btn-effect {
            &:hover {
                color: $black;

                &:before {
                    border-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .3) 102%, rgba(0, 0, 0, 0) 0);
                    border-image-slice: 1 !important;
                }

                &:after {
                    border-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .3) 102%, rgba(0, 0, 0, 0) 0);
                    border-image-slice: 1 !important;
                }
            }

            &.btn_send_fund {
                &:hover {
                    color: $black;
                }
            }
        }

        &.upload_btn {
            &:hover {
                border-color: var(--avatusColor);
                background-color: var(--avatusColor);
                color: $white;
            }
        }
    }

    .search_input {
        @media screen and (max-width: $breakpoint_480) {
            background-image: url('../images/ic_search.svg') !important;
        }
    }

    .recent_search {
        .data_list {
            .data_list_item {
                &:hover {
                    button {
                        color: $white;
                    }

                    svg {
                        fill: $white;
                    }
                }
            }
        }
    }

    .right_col {
        background-image: linear-gradient(to right, #FFFFFF26, #FFFFFF) !important;

        &::before {
            background-image: url('../../assets/images/onboard_bg_white_mobile.svg') !important;
        }
    }

    .onboardimg {
        background-image: url('../../assets/images/onboard_bg_white.svg') !important;
    }

    .listing {
        li {

            a,
            .button {

                &.channel,
                &.my_purchase,
                &.my_account,
                &.my_coupons {
                    &::before {
                        background: url('../images/channel_black.svg') center no-repeat;
                    }
                }

                &.my_channel {
                    &::before {
                        background: url('../images/my_channel_black.svg') center no-repeat;
                    }
                }

                &.shop {
                    &::before {
                        background: url('../images/browse_shop_black.svg') center no-repeat;
                    }
                }

                &.events {
                    &::before {
                        background: url('../images/browse_shop_black.svg') center no-repeat;
                    }
                }

                &.my_wishlist {
                    &::before {
                        background: url('../images/my-wishlist-black.svg') center no-repeat;
                    }
                }

                &.theme {
                    &::before {
                        background: url('../images/settings/settings_theme_black.svg') center no-repeat;
                    }
                }

                &.privacy {
                    &::before {
                        background: url('../images/settings/settings_privacy_black.svg') center no-repeat;
                    }
                }

                &.blocked {
                    &::before {
                        background: url('../images/settings/settings_blocked_black.svg') center no-repeat;
                    }
                }

                &.message {
                    &::before {
                        background: url('../images/settings/settings_messages_black.svg') center no-repeat;
                    }
                }

                &.notification {
                    &::before {
                        background: url('../images/settings/settings_notifications_black.svg') center no-repeat;
                    }
                }

                &.account {
                    &::before {
                        background: url('../images/settings/settings_account_black.svg') center no-repeat;
                    }
                }

                &.payment {
                    &::before {
                        background: url('../images/settings/settings_payment_black.svg') center no-repeat;
                    }
                }

                &.membership {
                    &::before {
                        background: url('../images/settings/settings_membership_black.svg') center no-repeat;
                    }
                }

                &.security {
                    &::before {
                        background: url('../images/settings/settings_security_black.svg') center no-repeat;
                    }
                }

                &.video {
                    &::before {
                        background: url('../images/settings/settings_thea_video_black.svg') center no-repeat;
                    }
                }

                &.report {
                    &::before {
                        background: url('../images/settings/settings_report_problem_black.svg') center no-repeat;
                    }
                }

                &.faq {
                    &::before {
                        background: url('../images/settings/settings_FAQ_black.svg') center no-repeat;
                    }
                }

                &.contact {
                    &::before {
                        background: url('../images/settings/settings_contact_us_black.svg') center no-repeat;
                    }
                }

                &.accessibility {
                    &::before {
                        background: url('../images/setting-accessibility-black.svg') center no-repeat;
                    }
                }

                &.active,
                &.NamedLink_active,
                &:hover {
                    background: url('../images/date-marker-light.svg') center no-repeat;
                    background-size: cover;
                }
            }
        }

        &.without_icons {
            li {

                a,
                .button {
                    background: url('../images/date-marker-light.svg') center no-repeat;
                    background-size: cover;
                    color: var(--sidebarTextColor);

                    &.active,
                    &.NamedLink_active,
                    &:hover {
                        color: var(--whiteColor);
                        background-image: url('../images/menu-selected-bg.svg');
                        background-size: 100%;
                    }
                }
            }
        }
    }

    .show_label {

        &.status {
            &::before {
                background: url('../../assets/images/active_status_black.svg') center no-repeat;
            }
        }

        &.recevied {
            &::before {
                background: url('../../assets/images/receive-messages_black.svg') center no-repeat;
            }
        }

        &.notif {
            &::before {
                background: url('../../assets/images/notifications/pause.svg') center no-repeat;
            }
        }

        &.reminder {
            &::before {
                background: url('../../assets/images/notifications/alarm.svg') center no-repeat;
            }
        }

        &.followers {
            &::before {
                background: url('../../assets/images/notifications/followers.svg') center no-repeat;
            }
        }

        &.follow {
            &::before {
                background: url('../../assets/images/notifications/follow_request.svg') center no-repeat;
            }
        }

        &.event {
            &::before {
                background: url('../../assets/images/notifications/event_creation.svg') center no-repeat;
            }
        }

        &.channel {
            &::before {
                background: url('../../assets/images/notifications/followed_channels.svg') center no-repeat;
            }
        }

        &.campaign {
            &::before {
                background: url('../../assets/images/notifications/campaigns.svg') center no-repeat;
            }
        }

        &.message {
            &::before {
                background: url('../../assets/images/chat_dark.svg') center no-repeat;
            }
        }
    }

    .no_data {
        .no_data_text {
            color: $black;
        }
    }

    .membership_tick {
        span {
            filter: brightness(.5);
        }
    }

    .cls-1 {
        fill: $black;
    }

    .dark_theme_img {
        display: none !important;
    }

    .light_theme_img {
        display: initial !important;
    }

    .invert {
        filter: invert(1);
    }

    .pseudo_invert {

        &::before,
        &::after {
            filter: invert(1);
        }
    }

    .replay_icon {
        color: $white;

        .cls-1 {
            fill: $white;
        }
    }

    .bgWhite {
        background-color: $white;
    }

    .placeholder_img {
        border-radius: 50%;
        border: 2px solid var(--headerLinksLight);
    }

    .card_slider {
        .react-multiple-carousel__arrow {

            &.react-multiple-carousel__arrow--left {
                background: url('../images/slider_arrow.svg') no-repeat scroll 0 0;
                transform: rotate(180deg);
                margin-top: -25px;
            }

            &.react-multiple-carousel__arrow--right {
                background: url('../images/slider_arrow.svg') no-repeat scroll 0 0;
                opacity: 1;
            }
        }
    }

    .feed_slider {
        .card_slider {
            .react-multiple-carousel__arrow {

                &.react-multiple-carousel__arrow--left,
                &.react-multiple-carousel__arrow--right {
                    background: url('../images/slider_arrow.svg') no-repeat scroll 0 0;
                    width: 30px;
                    height: 30px;
                }

                &.react-multiple-carousel__arrow--left {
                    transform: rotate(180deg);
                    margin-top: -25px;
                }
            }
        }
    }

    .mui_carousel {

        button[aria-label="Previous"],
        button[aria-label="Next"] {
            width: 30px;
            height: 30px;
            background: url('../images/slider_arrow.svg') no-repeat scroll 0 0;
            border-radius: 0;

            img {
                display: none;
            }
        }

        button[aria-label="Previous"] {
            transform: rotate(180deg);
        }
    }

    .isOpen {
        & .zoom_container {

            // &::before {
            //     background: url('../../assets/images/popup_bgwhite_after.svg') center no-repeat;
            //     background-size: cover;
            // }

            // &::after {
            //     background: url('../../assets/images/popup_bgwhite_after.svg') center no-repeat;
            //     background-size: cover;
            // }
        }

        .cross {
            background-color: var(--footerColor);
        }
    }

    .onboard_tip_label {
        color: $black;
    }

    .pwd_tips {
        li {
            list-style-image: url('../../assets/images/marker-white.svg');

            &.green {
                list-style-image: url('../../assets/images/marker-dark-green.svg');
                color: $successColorDark;
            }
        }

        &.onboard_pwd {
            li {
                color: $black;
                list-style-image: url('../../assets/images/marker-black.svg');

                &.green {
                    list-style-image: url('../../assets/images/marker-dark-green.svg');
                    color: $successColorDark;
                }

                &.red {
                    list-style-image: url('../../assets/images/marker-red.svg');
                    color: $failColor;
                }
            }
        }

        &.signup {
            background-color: $white;

            &::before {
                border-top-color: $white;
            }

            li {
                color: $black;
                list-style-image: url('../../assets/images/marker-black.svg');
            }
        }
    }


    .tabs_wrap {
        li {

            a,
            button {

                &::after {
                    background: var(--avatusColor);
                }
            }
        }
    }

    .nav_tabs {
        li {
            a {
                background-color: var(--matterColor);
                color: var(--lightGreyColor);

                &.active {
                    background-color: $white;
                    color: $black;
                }
            }
        }
    }

    .per_month {
        color: $black;
    }

    .looking_for {
        color: $black;
    }

    .filterBtn {
        svg {
            path {
                fill: $black !important;
            }
        }
    }

    .deleted {
        &::before {
            background: url('../../assets/images/deleted-message-white.svg') !important;
        }
    }

    .detail {
        .detail__list {
            .detail__item {
                .detail__link {
                    &::before {
                        background-color: rgba(0, 0, 0, 0.9);
                    }
                }
            }
        }
    }

    .dome_list_item {

        .dome_icon_light {
            border-color: $black;
            background-color: transparent;

            img {
                filter: invert(1);
            }
        }

        label {
            color: var(--lightGreyColor);
        }

        &.selected_dome_item {
            .dome_icon_light {
                background-color: $black;
                box-shadow: var(--domeBoxShadow);
            }

            label {
                color: $black;
                font-family: $fontFamilyMedium;
            }
        }
    }

    .filter_btn {
        border-color: $black;
    }

    .by_stars {
        li {
            color: $black;

            &::before {
                background: url('../../assets/images/star_black.svg') center no-repeat;
            }

            &.active {
                &::before {
                    background: url('../../assets/images/star_red.svg') center no-repeat;
                }
            }
        }
    }

    .black_box_bg {
        .detect_icon {
            svg {
                g {
                    stroke: $white;
                }

                path {
                    fill: $white;
                }
            }
        }

        .outline_btn {
            color: $white;
            border-color: var(--msgSendBg);
        }
    }

    .hex {
        .hex_hover {
            background-color: var(--avatusColorBgTwo) !important;
        }
    }

    .report_user_modal {
        .report_info {
            color: $black;
            font-family: $fontFamilyMedium;
        }
    }

    .profile_bg_dark {
        display: none;
    }

    .profile_bg_light {
        display: block;
        background-image: url('../images/avatus-bg-light.png');

        @media screen and (max-width: $breakpoint_1024) {
            background-image: url('../images/avatus-bg-light-mobile.png');
            background-position-y: 20%;
        }
    }

    .avatar_btn {
        background: url("../images/Customise.png") center no-repeat;
    }

    .btn-avatar_change {
        &:hover {
            color: $white !important;
        }
    }

    .avatar_outfit_step {
        background-image: url("../images/avatus-bg-light.png");

        @media screen and (max-width: $breakpoint_1024) {
            background-image: url('../images/avatus-bg-light-mobile.png');
            background-position-y: 25%;
        }
    }

    .avatar_modal_wrap {
        background-image: url('../images/avatus-bg-light.png') !important;

        @media screen and (max-width: $breakpoint_1024) {
            background-image: url('../images/avatus-bg-light-mobile.png') !important;
            background-position-y: 25%;
        }

        .landing_back_btn {
            color: $black;

            img{
                filter: invert(1);
            }
        }
    }

    .poweredby_unionavatars {
        img {
            filter: brightness(0);
        }
    }

    .outfit_wrapper {
        background-image: url('../../assets/images/avatus-bg-light.png') !important;

        @media screen and (max-width: $breakpoint_1024) {
            background-image: url('../../assets/images/avatus-bg-light-mobile.png') !important;
            background-position-y: 25%;
        }
    }
}