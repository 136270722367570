@font-face {
    font-family: 'PoppinsRegular';
    src: local(Poppins-Regular),
        url('../fonts/Poppins-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'PoppinsMedium';
    src: local(Poppins-Medium),
        url('../fonts/Poppins-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: local(Poppins-SemiBold),
        url('../fonts/Poppins-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'PoppinsBold';
    src: local(Poppins-Bold),
        url('../fonts/Poppins-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}